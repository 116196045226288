<app-step></app-step>

<div class="k-container">
  <div class="principal">
    <h4 class="titulo">{{ lang.title }}</h4>

    <div class="box-title box-financeResponsible">
      <h4 #stickyMenu [class.sticky]="sticky">
        <div class="inner-box">
          <i class="fas fa-file-invoice-dollar"></i>
          <div>{{ lang.caption }} <span> {{ lang.formTypeFinance }} </span></div>
        </div>
      </h4>
    </div>

    <label class="check checkbox sameGuardianValue">
      <input type="checkbox" [(ngModel)]="isSameGuardianValue" (ngModelChange)="setSameGuardianValue()" />
      <span class="selector"></span>
      <span>{{ lang.sameGuardianValue }}</span>
    </label>

    <div [class]="classeAtiva">
      <div class="tituloStep titulo1">
        <span class="tituloStep__numeroContainer">
          <span class="tituloStep__numero"> 7 </span></span>
        <span class="tituloStep__text"> {{ lang.stepFour }} </span>
      </div>


      <div class="spacer1"><span class="spacer__border"> </span></div>
      <div class="content1" *ngIf="steps[0].ativo">

        <form [formGroup]="formDadosFinanceiro" class="form1">
          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelFullName }} </label>
            <input
              class="ipt__campo"
              formControlName="nome"
              type="text"
              [placeholder]="lang.placeholderFullName"
              maxlength="40"
              (paste)="$event.preventDefault()"
              (keypress)="alphaOnly($event)" />
            <div
              *ngIf="
                formDadosFinanceiro.get('nome')?.invalid &&
                (formDadosFinanceiro.get('nome')?.dirty ||
                  formDadosFinanceiro.get('nome')?.touched)
              ">
              <app-input-error
                *ngIf="formDadosFinanceiro.get('nome')?.errors?.['required']"
                [textError]="langInputError.required"></app-input-error>
              <app-input-error
                *ngIf="formDadosFinanceiro.get('nome')?.errors?.['pattern']"
                [textError]="langInputError.incompleteFullName"></app-input-error>
            </div>
          </div>

          <div class="split-field">
            <div class="ipt">
              <label class="ipt__label">
                {{ lang.labelPersonalDocument }}
              </label>
              <input
                class="ipt__campo"
                formControlName="cpf"
                type="text"
                [placeholder]="lang.placeholderPersonalDocument"
                [mask]="personalDocumentFormat.documentMask"
                [attr.inputmode]="personalDocumentFormat.documentInputmode"
                [maxlength]="personalDocumentFormat.documentMaxlength"
                [patterns]="customPatterns" />
              <div
                *ngIf="
                  formDadosFinanceiro.get('cpf')?.invalid &&
                  (formDadosFinanceiro.get('cpf')?.dirty ||
                    formDadosFinanceiro.get('cpf')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('cpf')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('cpf')?.errors?.minlength"
                  [textError]="langInputError.incompletePersonalDocument"></app-input-error>
                <app-input-error
                  *ngIf="
                    formDadosFinanceiro.get('cpf')?.errors?.validCPF ||
                    formDadosFinanceiro.get('cpf')?.errors?.validRUT
                  "
                  [textError]="langInputError.invalidPersonalDocument"></app-input-error>
              </div>
            </div>

            <div class="select">
              <label class="ipt__label"> {{ lang.labelRelationship }} </label>
              <select
                class="ipt__campo"
                formControlName="relacao"
                #relacao
                (change)="
                  formataRelacao(
                    relacao.options[relacao.options.selectedIndex].text
                  )
                ">
                <option [selected]="true" value="" [hidden]="true">
                  {{ lang.placeholderRelationship }}
                </option>
                <option
                  *ngFor="let relacao of relacaoAluno"
                  value="{{ relacao.valor }}"
                  [selected]="relacao.tipo === 'Responsável'">
                  {{ relacao.tipo }}
                </option>
              </select>
              <div
                *ngIf="
                  formDadosFinanceiro.get('relacao')?.invalid &&
                  (formDadosFinanceiro.get('relacao')?.dirty ||
                    formDadosFinanceiro.get('relacao')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('relacao')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
              </div>
            </div>
          </div>

          <div class="split-field">
            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelHomePhone }} </label>
              <div class="container-code-number">
                <ng-select
                  *ngIf="!isSameGuardianValue"
                  appendTo=".ipt"
                  class="code"
                  [items]="allCountries"
                  bindLabel="name"
                  bindValue="dialCode"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="codeRes"
                  (change)="onSelectCountry($event.isoCode, 'telRes')">
                  <ng-template ng-label-tmp let-item="item">
                    <img
                      *ngIf="item.flag"
                      class="label-img"
                      [src]="item.flag"
                      [alt]="item.name" />
                    <span *ngIf="!item.flag" class="label-name">{{
                      item.name
                      }}</span>
                    <span class="label-code">{{ item.dialCode }}</span>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <img
                      *ngIf="item.flag"
                      class="option-img"
                      [src]="item.flag"
                      [alt]="item.name" />
                    <span class="option-name">{{ item.name }}</span>
                    <span class="option-code">{{ item.dialCode }}</span>
                  </ng-template>
                </ng-select>
                <input
                  class="ipt__campo"
                  formControlName="telRes"
                  type="text"
                  [placeholder]="phoneFormat.placeholder.telRes"
                  [mask]="phoneFormat.mask.telRes"
                  inputmode="numeric" />
              </div>

              <div
                *ngIf="
                  formDadosFinanceiro.get('telRes')?.invalid &&
                  (formDadosFinanceiro.get('telRes')?.dirty ||
                    formDadosFinanceiro.get('telRes')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('telRes')?.errors?.minlength"
                  [textError]="langInputError.incompletePhone"></app-input-error>
              </div>
            </div>

            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelWorkPhone }} </label>

              <div class="container-code-number">
                <ng-select
                  *ngIf="!isSameGuardianValue"
                  appendTo=".ipt"
                  class="code"
                  [items]="allCountries"
                  bindLabel="name"
                  bindValue="dialCode"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="codeCom"
                  (change)="onSelectCountry($event.isoCode, 'telCom')">
                  <ng-template ng-label-tmp let-item="item">
                    <img
                      *ngIf="item.flag"
                      class="label-img"
                      [src]="item.flag"
                      [alt]="item.name" />
                    <span *ngIf="!item.flag" class="label-name">{{
                      item.name
                      }}</span>
                    <span class="label-code">{{ item.dialCode }}</span>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <img
                      *ngIf="item.flag"
                      class="option-img"
                      [src]="item.flag"
                      [alt]="item.name" />
                    <span class="option-name">{{ item.name }}</span>
                    <span class="option-code">{{ item.dialCode }}</span>
                  </ng-template>
                </ng-select>
                <input
                  class="ipt__campo"
                  formControlName="telCom"
                  type="text"
                  [placeholder]="phoneFormat.placeholder.telCom"
                  [mask]="isSameGuardianValue ? null : phoneFormat.mask.telCom"
                  inputmode="numeric" />
              </div>

              <div
                *ngIf="
                  formDadosFinanceiro.get('telCom')?.invalid &&
                  (formDadosFinanceiro.get('telCom')?.dirty ||
                    formDadosFinanceiro.get('telCom')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('telCom')?.errors?.minlength"
                  [textError]="langInputError.incompletePhone"></app-input-error>
              </div>
            </div>
          </div>

          <div class="split-field">
            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelCellphone }} </label>

              <div class="container-code-number">
                <ng-select
                  *ngIf="!isSameGuardianValue"
                  appendTo=".ipt"
                  class="code"
                  [items]="allCountries"
                  bindLabel="name"
                  bindValue="dialCode"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="codeCel"
                  (change)="onSelectCountry($event.isoCode, 'telCel')">
                  <ng-template ng-label-tmp let-item="item">
                    <img
                      *ngIf="item.flag"
                      class="label-img"
                      [src]="item.flag"
                      [alt]="item.name" />
                    <span *ngIf="!item.flag" class="label-name">{{
                      item.name
                      }}</span>
                    <span class="label-code">{{ item.dialCode }}</span>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <img
                      *ngIf="item.flag"
                      class="option-img"
                      [src]="item.flag"
                      [alt]="item.name" />
                    <span class="option-name">{{ item.name }}</span>
                    <span class="option-code">{{ item.dialCode }}</span>
                  </ng-template>
                </ng-select>
                <input
                  class="ipt__campo"
                  formControlName="telCel"
                  type="text"
                  [placeholder]="phoneFormat.placeholder.telCel"
                  [mask]="phoneFormat.mask.telCel"
                  inputmode="numeric" />
              </div>

              <div
                *ngIf="
                  formDadosFinanceiro.get('telCel')?.invalid &&
                  (formDadosFinanceiro.get('telCel')?.dirty ||
                    formDadosFinanceiro.get('telCel')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('telCel')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('telCel')?.errors?.minlength"
                  [textError]="langInputError.incompletePhone"></app-input-error>
              </div>
            </div>

            <div class="ipt iptEmail">
              <label class="ipt__label"> {{ lang.labelEmail }} </label>
              <input
                class="ipt__campo"
                formControlName="email"
                type="email"
                maxlength="100"
                [placeholder]="lang.placeholderEmail" />
              <div
                *ngIf="
                  formDadosFinanceiro.get('email')?.invalid &&
                  (formDadosFinanceiro.get('email')?.dirty ||
                    formDadosFinanceiro.get('email')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('email')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formDadosFinanceiro.get('email')?.errors?.['pattern']"
                  [textError]="langInputError.invalidEmail"></app-input-error>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="tituloStep titulo2">
        <span class="tituloStep__numeroContainer">
          <span class="tituloStep__numero"> 8 </span>
        </span>
        <span class="tituloStep__text"> {{ lang.stepFive }} </span>
      </div>

      <div class="spacer2"><span class="spacer__border"> </span></div>
      <div class="content2" *ngIf="steps[1].ativo">
        <form [formGroup]="formEnderecoFinanceiro">

          <label class="check checkbox mesmoEndereco" *ngIf="!isSameGuardianValue">
            <input type="checkbox" (change)="mesmoEndereco()" />
            <span class="selector"></span>
            <span> {{ lang.labelSameAdress }} </span>
          </label>

          <div
            class="ipt"
            *ngIf="displayForm.cep"
            [style.order]="addressOrder.cep">
            <label class="ipt__label">
              {{ langEndereco.labelPostalCode }}
            </label>
            <input
              class="ipt__campo"
              type="text"
              formControlName="cep"
              [placeholder]="langEndereco.placeholderPostalCode"
              mask="00000-000"
              (keyup)="consultaCEP($event)"
              (keypress)="consultaCEP($event)"
              inputmode="numeric"
              [tabindex]="addressOrder.cep" />
            <app-loading-inner *ngIf="isLoading"></app-loading-inner>
            <app-input-error
              [textError]="langInputError.postalCodeNotFound"
              *ngIf="isCEPinvalid"></app-input-error>
            <div
              *ngIf="
                formEnderecoFinanceiro.get('cep')?.invalid &&
                (formEnderecoFinanceiro.get('cep')?.dirty ||
                  formEnderecoFinanceiro.get('cep')?.touched)
              ">
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('cep')?.errors?.['required']"
                [textError]="langInputError.required"></app-input-error>
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('cep')?.errors?.minlength"
                [textError]="langInputError.incompletePostalCode"></app-input-error>
            </div>
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.endereco"
            [style.order]="addressOrder.endereco">
            <label class="ipt__label"> {{ langEndereco.labelAddress1 }} </label>
            <input
              class="ipt__campo"
              type="text"
              formControlName="endereco"
              maxlength="50"
              [placeholder]="langEndereco.placeholderAddress1"
              [tabindex]="addressOrder.endereco" />
            <div
              *ngIf="
                formEnderecoFinanceiro.get('endereco')?.invalid &&
                (formEnderecoFinanceiro.get('endereco')?.dirty ||
                  formEnderecoFinanceiro.get('endereco')?.touched)
              ">
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('endereco')?.errors?.['required']"
                [textError]="langInputError.required"></app-input-error>
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('endereco')?.errors?.['pattern']"
                [textError]="langInputError.required"></app-input-error>
            </div>
          </div>

          <div
            class="split-field"
            [style.order]="addressOrder.splitField.order">
            <div
              class="ipt"
              *ngIf="displayForm.numero"
              [style.order]="addressOrder.splitField.numero">
              <label class="ipt__label">
                {{ langEndereco.labelAddress2 }}
              </label>
              <input
                class="ipt__campo"
                type="text"
                formControlName="numero"
                [placeholder]="langEndereco.placeholderAddress2"
                maxlength="40"
                [tabindex]="addressOrder.splitField.numero" />
              <div
                *ngIf="
                  formEnderecoFinanceiro.get('numero')?.invalid &&
                  (formEnderecoFinanceiro.get('numero')?.dirty ||
                    formEnderecoFinanceiro.get('numero')?.touched)
                ">
                <app-input-error
                  *ngIf="formEnderecoFinanceiro.get('numero')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formEnderecoFinanceiro.get('numero')?.errors?.['pattern']"
                  [textError]="langInputError.required"></app-input-error>
              </div>
            </div>

            <div
              class="ipt"
              *ngIf="displayForm.complemento"
              [style.order]="addressOrder.splitField.complemento">
              <label class="ipt__label">
                {{ langEndereco.labelAddress3 }}
              </label>
              <input
                class="ipt__campo"
                type="text"
                formControlName="complemento"
                [placeholder]="langEndereco.placeholderAddress3"
                maxlength="40"
                [tabindex]="addressOrder.splitField.complemento" />
            </div>
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.bairro"
            [style.order]="addressOrder.bairro">
            <label class="ipt__label"> {{ langEndereco.labelAddress4 }} </label>
            <input
              class="ipt__campo"
              formControlName="bairro"
              type="text"
              [placeholder]="langEndereco.placeholderAddress4"
              maxlength="40"
              [tabindex]="addressOrder.bairro" />
            <div
              *ngIf="
                formEnderecoFinanceiro.get('bairro')?.invalid &&
                (formEnderecoFinanceiro.get('bairro')?.dirty ||
                  formEnderecoFinanceiro.get('bairro')?.touched)
              ">
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('bairro')?.errors?.['required']"
                [textError]="langInputError.required"></app-input-error>
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('bairro')?.errors?.['pattern']"
                [textError]="langInputError.required"></app-input-error>
            </div>
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.estado"
            [style.order]="addressOrder.estado">
            <label class="ipt__label"> {{ langEndereco.labelState }} </label>
            <select
              class="ipt__campo"
              formControlName="estado"
              #estado
              [tabindex]="addressOrder.estado"
              (change)="
                formataEstado(estado.options[estado.options.selectedIndex].text)
              ">
              <option value="0" [selected]="true" [hidden]="true">
                {{ langEndereco.placeholderState }}
              </option>
              <option *ngFor="let estado of estados" [value]="estado.sigla">
                {{ estado.nome }}
              </option>
            </select>
            <div
              *ngIf="
                formEnderecoFinanceiro.get('estado')?.invalid &&
                (formEnderecoFinanceiro.get('estado')?.dirty ||
                  formEnderecoFinanceiro.get('estado')?.touched)
              ">
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('estado')?.errors?.['required']"
                [textError]="langInputError.required"></app-input-error>
            </div>
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.cidade"
            [style.order]="addressOrder.cidade">
            <label class="ipt__label"> {{ langEndereco.labelCity }} </label>
            <input
              class="ipt__campo"
              type="text"
              formControlName="cidade"
              maxlength="35"
              [placeholder]="langEndereco.placeholderCity"
              [tabindex]="addressOrder.cidade" />
            <div
              *ngIf="
                formEnderecoFinanceiro.get('cidade')?.invalid &&
                (formEnderecoFinanceiro.get('cidade')?.dirty ||
                  formEnderecoFinanceiro.get('cidade')?.touched)
              ">
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('cidade')?.errors?.['required']"
                [textError]="langInputError.required"></app-input-error>
              <app-input-error
                *ngIf="formEnderecoFinanceiro.get('cidade')?.errors?.['pattern']"
                [textError]="langInputError.required"></app-input-error>
            </div>
          </div>
        </form>
      </div>

      <div class="tituloStep titulo3">
        <span class="tituloStep__numeroContainer">
          <span class="tituloStep__numero"> 9 </span>
        </span>
        <span class="tituloStep__text"> {{ lang.stepSix }} </span>
      </div>
      <div class="spacer3"><span class="spacer__border"> </span></div>
      <div class="content3" *ngIf="steps[2].ativo">
        <p class="tituloValidacao">
          {{ lang.captionStepSix }}
        </p>
        <div class="ipt">
          <label class="ipt__label"> {{ lang.labelFullName }} </label>
          <input
            class="ipt__campo"
            readonly
            type="text"
            value="{{ formDadosFinanceiro.get('nome')?.value }}" />
        </div>

        <div class="split-field">
          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelPersonalDocument }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{
                country === 'CL'
                ? (formDadosFinanceiro.get('cpf')?.value
                 | mask : documentMaskRUT)
                : (formDadosFinanceiro.get('cpf')?.value
                 | mask : personalDocumentFormat.documentMask)
              }}" />
          </div>

          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelRelationship }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{ relacaoAlunoTipo }}" />
          </div>
        </div>

        <div class="split-field">
          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelHomePhone }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{
                formDadosFinanceiro.get('telRes')?.value
                  | mask : phoneFormat.mask.telRes
              }}" />
          </div>

          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelWorkPhone }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{
                formDadosFinanceiro.get('telCom')?.value
                  | mask : phoneFormat.mask.telCom
              }}" />
          </div>
        </div>

        <div class="split-field">
          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelCellphone }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{ formDadosFinanceiro.get('telCel')?.value }}" />
          </div>

          <div class="ipt iptEmail">
            <label class="ipt__label"> {{ lang.labelEmail }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{ formDadosFinanceiro.get('email')?.value }}" />
          </div>
        </div>

        <div
          class="ipt"
          *ngIf="displayForm.cep"
          [style.order]="addressOrder.cep">
          <label class="ipt__label"> {{ langEndereco.labelPostalCode }} </label>
          <input
            class="ipt__campo"
            readonly
            type="text"
            value="{{
              formEnderecoFinanceiro.get('cep')?.value | mask : '00000-000'
            }}" />
        </div>

        <div
          class="ipt"
          *ngIf="displayForm.endereco"
          [style.order]="addressOrder.endereco">
          <label class="ipt__label"> {{ langEndereco.labelAddress1 }} </label>
          <input
            class="ipt__campo"
            readonly
            type="text"
            value="{{ formEnderecoFinanceiro.get('endereco')?.value }}" />
        </div>

        <div class="split-field" [style.order]="addressOrder.splitField.order">
          <div
            class="ipt"
            *ngIf="displayForm.numero"
            [style.order]="addressOrder.splitField.numero">
            <label class="ipt__label"> {{ langEndereco.labelAddress2 }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{ formEnderecoFinanceiro.get('numero')?.value }}" />
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.complemento"
            [style.order]="addressOrder.splitField.complemento">
            <label class="ipt__label"> {{ langEndereco.labelAddress3 }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{ formEnderecoFinanceiro.get('complemento')?.value }}" />
          </div>
        </div>

        <div
          class="ipt"
          *ngIf="displayForm.bairro"
          [style.order]="addressOrder.bairro">
          <label class="ipt__label"> {{ langEndereco.labelAddress4 }} </label>
          <input
            class="ipt__campo"
            type="text"
            readonly
            value="{{ formEnderecoFinanceiro.get('bairro')?.value }}" />
        </div>

        <div
          class="ipt"
          *ngIf="displayForm.estado"
          [style.order]="addressOrder.estado">
          <label class="ipt__label"> {{ langEndereco.labelState }} </label>
          <input
            class="ipt__campo"
            readonly
            type="text"
            value="{{ estadoNome }}" />
        </div>

        <div
          class="ipt"
          *ngIf="displayForm.cidade"
          [style.order]="addressOrder.cidade">
          <label class="ipt__label"> {{ langEndereco.labelCity }} </label>
          <input
            class="ipt__campo"
            readonly
            type="text"
            value="{{ formEnderecoFinanceiro.get('cidade')?.value }}" />
        </div>
      </div>
    </div>

    <div class="navigate-button">
      <button class="botaoVoltar" (click)="paginaAnterior()">
        <i class="fas fa-arrow-alt-circle-left"></i>
        <span> {{ langButton.back }} </span>
      </button>
      <button class="botaoContinuar" (click)="proximaPagina()">
        <span> {{ langButton.continue }} </span>
        <i class="fas fa-arrow-alt-circle-right"></i>
      </button>
    </div>
  </div>
</div>

<app-help-button></app-help-button>