import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CountryList } from 'src/app/models/country-list.model'
import { ParentData } from 'src/app/models/enrollment.model'
import { DocumentFormat, PhoneType } from 'src/app/models/format.model'
import { Address, Buttons, CadastroResponsavel, ErrorInput } from 'src/app/models/language.model'
import { ApisService } from 'src/app/services/apis.service'
import { CountryListService } from 'src/app/services/country-list.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { validCPF, validRUT } from '../../shared/valildators/form.validator'
import { ConfirmService } from './../../services/confirm.service'


@Component({
  selector: 'app-cadastro-responsavel',
  templateUrl: './cadastro-responsavel.component.html',
  styleUrls: ['./cadastro-responsavel.component.scss']
})
export class CadastroResponsavelComponent implements OnInit {

  lang: CadastroResponsavel = this.language.cadastroResponsavel
  langEndereco: Address = this.language.endereco
  langButton: Buttons = this.language.buttons
  langInputError: ErrorInput = this.language.errorInput

  country = this.dados.getDados().countryId
  classeAtiva = "containerStepper-vertical"
  stepAtivo = 0
  isLoading = false
  isCEPinvalid = false
  setmesmoEnderecoAluno = false

  relacaoAluno = this.language.relacaoAluno
  relacaoAlunoTipo = ''
  estados = this.language.estados
  estadoNome = ''

  dadosResponsavelConsent = {
    nome: '',
    email: '',
    telCel: '',
    codeRes: '',
    codeCom: '',
    codeCel: ''
  }

  displayForm = {
    cep: true,
    endereco: true,
    numero: true,
    complemento: true,
    bairro: true,
    estado: true,
    cidade: true
  }

  personalDocumentFormat: DocumentFormat = {
    documentMask: '000.000.000-00',
    documentInputmode: 'numeric',
    documentMaxlength: null
  }

  documentMaskRUT = ''

  phoneFormat = {
    mask: {
      telRes: '',
      telCom: '',
      telCel: ''
    },
    placeholder: {
      telRes: '',
      telCom: '',
      telCel: ''
    }
  }

  addressOrder = {
    cep: 0,
    endereco: 0,
    splitField: {
      order: 0,
      numero: 0,
      complemento: 0,
    },
    bairro: 0,
    estado: 0,
    cidade: 0
  }

  allCountries: CountryList[] = this.countryService.allCountries

  steps = [
    {
      'nome': 'step-1',
      'ativo': true
    },
    {
      'nome': 'step-2',
      'ativo': false
    },
    {
      'nome': 'step-3',
      'ativo': false
    }
  ];

  responsavel: ParentData = {
    relationship: '',
    firstName: '',
    middleName: '',
    lastName: '',
    personalDocument: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    home_phone: '',
    work_phone: '',
    cellphone: '',
    email: ''
  }

  passByDialLength!: number

  customPatterns: any

  formDadosResponsavel = new FormGroup({
    nome: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/[a-zA-ZÀ-ž]+\s+[a-zA-ZÀ-ž]+/)]),
    cpf: new FormControl('', [Validators.required, Validators.minLength(11), validCPF]),
    relacao: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    telRes: new FormControl(),
    telCom: new FormControl(),
    telCel: new FormControl({ value: '', disabled: true }),
    codeRes: new FormControl(),
    codeCom: new FormControl(),
    codeCel: new FormControl()
  })

  formEnderecoResponsavel = new FormGroup({
    cep: new FormControl('', [Validators.required, Validators.minLength(8)]),
    endereco: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)]),
    numero: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z0-9]+/)]),
    complemento: new FormControl(''),
    bairro: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)]),
    estado: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)])
  })

  @ViewChild('stickyMenu') menuElement!: ElementRef
  sticky: boolean = false;
  elementPosition: any

  constructor(
    private router: Router,
    private apiService: ApisService,
    private confirm: ConfirmService,
    private http: HttpClient,
    private el: ElementRef,
    private dados: DadosService,
    private language: LanguageService,
    private countryService: CountryListService
  ) { }

  @HostListener('window:scroll', ['$event'])
  handleScroll(e: any) {
    const windowScroll = window.scrollY

    if (windowScroll >= this.elementPosition) {
      this.sticky = true
    } else {
      this.sticky = false
    }
  }

  @HostListener('window:resize', ['$event'])
  handleResize(e: any) {
    this.elementPosition = this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY

  }

  ngAfterViewInit() {
    this.elementPosition =
      this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY

    setTimeout(() => {
      this.elementPosition =
        this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY
    })

  }

  ngOnInit(): void {
    this.handleInitialData()
    this.changeFormByCountry()

    this.changePhoneFormat(
      this.dados.getDados().selectedPhoneParent || this.country,
      ['telRes', 'telCom', 'telCel']
    )

  }

  async handleInitialData() {

    if (sessionStorage.getItem('dadosResponsavel')) {

      this.populaCamposVoltarEtapa()
      this.formDadosResponsavel.controls['nome'].disable()
      this.formDadosResponsavel.controls['email'].disable()

    } else if (sessionStorage.getItem('ksis')) {

      const { dados, endereco } = await this.getDadosKsis()

      this.formDadosResponsavel.patchValue(dados)
      this.formEnderecoResponsavel.patchValue(endereco)

    } else {
      this.populaCamposConsent()
    }

  }

  getDadosKsis() {
    return new Promise<any>(resolve => {

      const { guardians } = JSON.parse(sessionStorage.getItem('ksis')!)

      // const responsibleGuardian = guardians.length === 1
      //   ? guardians[0]
      //   : guardians.find((item: any) => item.financeResponsibleFlag === false)

      const index = guardians.findIndex((item: any) => item.financeResponsibleFlag === false)

      const responsibleGuardian = index > 0
        ? guardians[index]
        : guardians[0]

      const filtroEstado = this.estados.filter((item: any) => responsibleGuardian.stateCode === item.sigla)

      const filtroRelacao = this.relacaoAluno.filter((item: { valor: number }) => responsibleGuardian.guardianType == item.valor)

      const responsavel = {
        dados: {
          nome: this.apiService.removeSpaces(this.dados.getDados().parentName),
          cpf: responsibleGuardian.officialDocNumber || '',
          relacao: filtroRelacao.length > 0 ? filtroRelacao[0].valor.trim() : '',
          email: this.apiService.removeSpaces(this.dados.getDados().emailResponsavel),
          telRes: responsibleGuardian.phoneNumberHome,
          telCom: responsibleGuardian.phoneNumberOffice,
          telCel: this.dados.getDados().cellphone,
          codeRes: this.dados.getDados().selectedPhoneParent?.telRes?.dialCode
            ?? this.countryService.getInfoCountry(this.country).dialCode,
          codeCom: this.dados.getDados().selectedPhoneParent?.telCom?.dialCode
            ?? this.countryService.getInfoCountry(this.country).dialCode,
          codeCel: this.dados.getDados().selectedPhoneParent?.telCel?.dialCode
            ?? this.countryService.getInfoCountry(this.country).dialCode
        },
        endereco: {
          cep: this.apiService.removeSpaces(responsibleGuardian.zipCode || ''),
          endereco: this.apiService.removeSpaces(responsibleGuardian.address1),
          numero: this.apiService.removeSpaces(responsibleGuardian.address2),
          complemento: this.apiService.removeSpaces(responsibleGuardian.address3),
          bairro: this.apiService.removeSpaces(responsibleGuardian.address4),
          estado: filtroEstado.length > 0 ? filtroEstado[0].sigla : '',
          cidade: this.apiService.removeSpaces(responsibleGuardian.city || '')
        }
      }

      this.filterOptions(responsavel.endereco, responsavel.dados)

      resolve(responsavel)
    })
  }


  getDadosApiConsentOnInit() {
    if (!this.dados.hasDados()) return

    return new Promise<void>(resolve => {

      this.dadosResponsavelConsent = {
        nome: this.dados.getDados().parentName,
        email: this.dados.getDados().emailResponsavel,
        telCel: this.dados.getDados().cellphone,
        codeRes: this.dados.getDados().selectedPhoneParent?.telRes?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode,
        codeCom: this.dados.getDados().selectedPhoneParent?.telCom?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode,
        codeCel: this.dados.getDados().selectedPhoneParent?.telCel?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode
      }
      resolve()
    })

  }

  async populaCamposConsent() {

    await this.getDadosApiConsentOnInit()

    this.formDadosResponsavel.patchValue(this.dadosResponsavelConsent)

    this.formDadosResponsavel.controls['nome'].disable()
    this.formDadosResponsavel.controls['email'].disable()

  }

  mudaEtapa(id: number) {
    this.steps.forEach((el) => {
      el.ativo = false
    })

    this.steps[id].ativo = true

    window.scroll(0, 330)
  }

  proximaPagina() {

    if (this.validarParte()) {
      if (this.stepAtivo < this.steps.length - 1) {
        this.stepAtivo++
        this.mudaEtapa(this.stepAtivo)
      } else {

        let name = this.apiService.splitName(
          this.apiService.removeSpaces(this.formDadosResponsavel.getRawValue().nome, true)
        )

        this.responsavel = {
          relationship: this.formDadosResponsavel.value.relacao,
          firstName: name.firstName,
          middleName: name.middleName,
          lastName: name.lastName,
          personalDocument: this.apiService.removeSpaces(this.formDadosResponsavel.value.cpf.replace(/\.|-/g, '')),
          address1: this.apiService.removeSpaces(this.formEnderecoResponsavel.value.endereco),
          address2: this.apiService.removeSpaces(this.formEnderecoResponsavel.value.numero),
          address3: this.apiService.removeSpaces(this.formEnderecoResponsavel.value.complemento),
          address4: this.apiService.removeSpaces(this.formEnderecoResponsavel.value.bairro),
          city: this.apiService.removeSpaces(this.formEnderecoResponsavel.value.cidade),
          state: this.formEnderecoResponsavel.getRawValue().estado,
          postalCode: this.formEnderecoResponsavel.value.cep,
          country: this.dados.getDados().countryId,
          home_phone: !this.formDadosResponsavel.value.telRes ?
            '' :
            this.formDadosResponsavel.value.codeRes + this.formDadosResponsavel.getRawValue().telRes,
          work_phone: !this.formDadosResponsavel.value.telCom ?
            '' :
            this.formDadosResponsavel.value.codeCom + this.formDadosResponsavel.getRawValue().telCom,
          cellphone: this.formDadosResponsavel.getRawValue().telCel,
          email: this.apiService.removeSpaces(this.formDadosResponsavel.getRawValue().email)
        }

        let dadosResponsavel = [
          this.formDadosResponsavel.getRawValue(),
          this.formEnderecoResponsavel.getRawValue()
        ]

        sessionStorage.setItem("dadosResponsavel", JSON.stringify(dadosResponsavel))

        this.confirm.setConfirm({ parentData: [this.responsavel] })

        this.router.navigate(['/cadastro-responsavel-financeiro'])
      }
    }
  }

  validarParte() {

    if (this.stepAtivo == 0) {
      if (!this.formDadosResponsavel.valid) {
        this.setFocus(this.formDadosResponsavel.controls)
        this.formDadosResponsavel.markAllAsTouched()
        return false
      }
    } else {
      if (!this.formEnderecoResponsavel.valid) {
        this.setFocus(this.formEnderecoResponsavel.controls)
        this.formEnderecoResponsavel.markAllAsTouched()
        return false
      }
    }

    this.documentMaskRUT = this.formDadosResponsavel.value.cpf.length === 8
      ? '0.000.000-A'
      : '00.000.000-A'

    return true
  }

  setFocus(formControl: any) {
    for (const key of Object.keys(formControl)) {
      if (formControl[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formControlName="' + key + '"]')
        invalidControl.focus()
        break
      }
    }
  }

  paginaAnterior() {
    if (this.stepAtivo != 0) {
      this.stepAtivo--
      this.mudaEtapa(this.stepAtivo)
    } else {
      this.router.navigate(['cadastro-aluno'])
    }
  }

  populaCamposVoltarEtapa() {

    const dadosResponsavel = JSON.parse(sessionStorage.getItem('dadosResponsavel')!)

    // if (!dadosResponsavel) return

    const sessionData = dadosResponsavel
    const formDados = Object.keys(this.formDadosResponsavel.value)
    const formEndereco = Object.keys(this.formEnderecoResponsavel.value)

    formDados.forEach(el => {

      if (el in sessionData[0]) {
        return this.formDadosResponsavel.patchValue(
          {
            ...sessionData[0],
          }
        )
      }
    })


    formEndereco.forEach(el => {
      if (el in sessionData[1]) {
        return this.formEnderecoResponsavel.patchValue(
          {
            ...sessionData[1]
          }
        )
      }
    })

    this.formDadosResponsavel.controls['nome'].disable()
    this.formDadosResponsavel.controls['email'].disable()

    this.filterOptions(this.formEnderecoResponsavel.value, this.formDadosResponsavel.value)

  }

  filterOptions(addressValue: any, dataValue: any) {

    const filtroEstado = this.estados.filter(item => addressValue.estado === item.sigla)
    const filtroRelacao = this.relacaoAluno.filter((item: { valor: number }) => Number(dataValue.relacao) === item.valor)

    const estado = filtroEstado.length > 0 ? filtroEstado[0].nome : ''
    const relacao = filtroRelacao.length > 0 ? filtroRelacao[0].tipo : ''

    if (!this.formEnderecoResponsavel.controls.cep.disabled) this.formataEstado(estado)

    this.formataRelacao(relacao)

  }

  onSelectCountry(isoCode: any, phoneType: PhoneType) {

    let selectedPhoneParent = {
      ...this.dados.getDados().selectedPhoneParent,
      [phoneType]: {
        isoCode: this.countryService.getInfoCountry(isoCode).isoCode,
        dialCode: this.countryService.getInfoCountry(isoCode).dialCode,
      }
    }

    this.formDadosResponsavel.patchValue({ [phoneType]: '' })

    this.changePhoneFormat(selectedPhoneParent, [phoneType])

    this.dados.setDados({ selectedPhoneParent })

  }

  changePhoneFormat(isoCode: any, phoneType: Array<string>) {

    let selected: any, mask, placeholder, minLength, phoneKey

    phoneType.forEach(item => {

      phoneKey = isoCode[item] ? isoCode[item].isoCode : this.country

      selected = typeof isoCode === 'string'
        ? this.countryService.getInfoCountry(isoCode)
        : this.countryService.getInfoCountry(phoneKey)

      for (const key in this.phoneFormat.mask) {

        if (key === item) {

          if (item === 'telCel') {

            mask = selected.cellphone.format
            placeholder = selected.cellphone.placehoder
            minLength = selected.cellphone.minLength
            this.formDadosResponsavel.get(key)?.setValidators([Validators.required, Validators.minLength(minLength)])

          } else {

            mask = selected.homePhone.format
            placeholder = selected.homePhone.placehoder
            minLength = selected.homePhone.minLength
            this.formDadosResponsavel.get(key)?.setValidators([Validators.minLength(minLength)])

          }

          this.phoneFormat.mask[key as PhoneType] = mask
          this.phoneFormat.placeholder[key as PhoneType] = placeholder
          this.formDadosResponsavel.get(key)?.updateValueAndValidity()
        }

      }

    })

  }

  changeFormByCountry() {

    switch (this.country) {

      case 'CL':
        this.personalDocumentFormat = {
          documentMask: '0.000.000-A||00.000.000-A',
          documentInputmode: 'text',
          documentMaxlength: null,
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          estado: false
        }

        this.formDadosResponsavel.get('cpf')?.setValidators([Validators.required, validRUT])

        this.formDadosResponsavel.updateValueAndValidity()
        this.formEnderecoResponsavel.updateValueAndValidity()

        this.formEnderecoResponsavel.controls['cep'].disable()
        this.formEnderecoResponsavel.controls['estado'].disable()

        this.formEnderecoResponsavel.patchValue({
          estado: 'CL'
        })

        break

      case 'CO':

        //this.customPatterns = { 'A': { pattern: new RegExp("^[A-Za-z0-9]*$"), optional: true } }

        this.personalDocumentFormat = {
          documentMask: "9999999999",
          documentInputmode: 'numeric',
          documentMaxlength: '10'
        }

        this.displayForm = {
          ...this.displayForm,
          numero: false,
          cep: false,
          estado: false
        }

        this.addressOrder = {
          ...this.addressOrder,
          cidade: 1,
          bairro: 2,
          endereco: 3,
          splitField: {
            order: 4,
            numero: 5,
            complemento: 6,
          },
        }

        this.formDadosResponsavel.get('cpf')?.setValidators([Validators.required, Validators.minLength(1)])

        this.formDadosResponsavel.updateValueAndValidity()
        this.formEnderecoResponsavel.updateValueAndValidity()

        this.formEnderecoResponsavel.controls['numero'].disable()
        this.formEnderecoResponsavel.controls['cep'].disable()
        this.formEnderecoResponsavel.controls['estado'].disable()

        this.formEnderecoResponsavel.patchValue({
          estado: 'CO'
        })

        break

      case 'AR':
        this.personalDocumentFormat = {
          documentMask: '00.000.000',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          estado: false
        }

        this.formDadosResponsavel.get('cpf')?.setValidators([Validators.required, Validators.minLength(8)])

        this.formDadosResponsavel.updateValueAndValidity()
        this.formEnderecoResponsavel.updateValueAndValidity()

        this.formEnderecoResponsavel.controls['cep'].disable()
        this.formEnderecoResponsavel.controls['estado'].disable()

        this.formEnderecoResponsavel.patchValue({
          estado: 'AR'
        })

        break


      case 'BO':
        this.personalDocumentFormat = {
          documentMask: '99999999999',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false
        }

        this.formDadosResponsavel.get('cpf')?.setValidators([Validators.required, Validators.minLength(7)])

        this.formDadosResponsavel.updateValueAndValidity()
        this.formEnderecoResponsavel.updateValueAndValidity()

        this.formEnderecoResponsavel.controls['cep'].disable()

        break

      case 'PE':
        this.personalDocumentFormat = {
          documentMask: '00000000',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false
        }

        this.formDadosResponsavel.get('cpf')?.setValidators([Validators.required, Validators.minLength(8)])

        this.formDadosResponsavel.updateValueAndValidity()
        this.formEnderecoResponsavel.updateValueAndValidity()

        this.formEnderecoResponsavel.controls['cep'].disable()

        break

      case 'UY':
        this.personalDocumentFormat = {
          documentMask: '0.000.000-0',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          bairro: false
        }

        this.formDadosResponsavel.get('cpf')?.setValidators([Validators.required, Validators.minLength(8)])

        this.formDadosResponsavel.updateValueAndValidity()
        this.formEnderecoResponsavel.updateValueAndValidity()

        this.formEnderecoResponsavel.controls['cep'].disable()
        this.formEnderecoResponsavel.controls['bairro'].disable()

        break

    }
  }


  formataRelacao(option: any) {
    this.relacaoAlunoTipo = option
  }

  formataEstado(option: any) {
    this.estadoNome = option
  }

  mesmoEndereco() {
    let enderecoAluno = JSON.parse(sessionStorage.getItem("dadosAluno") || '{}')

    this.formEnderecoResponsavel.setValue({
      cep: enderecoAluno[1].cep,
      endereco: enderecoAluno[1].endereco,
      numero: enderecoAluno[1].numero,
      complemento: enderecoAluno[1].complemento,
      bairro: enderecoAluno[1].bairro,
      estado: enderecoAluno[1].estado,
      cidade: enderecoAluno[1].cidade,
    })

    let filtro = this.estados.filter(el => {
      return el.sigla === enderecoAluno[1].estado
    })

    if (!this.formEnderecoResponsavel.get('estado')?.disabled) {
      this.formataEstado(filtro[0].nome)
    }

  }

  consultaCEP(e: any) {

    let cep = this.formEnderecoResponsavel.get('cep')?.value

    // this.isCEPinvalid = false


    if (cep != '' && cep.length === 8) {

      this.isLoading = true

      this.http.get(`//viacep.com.br/ws/${cep}/json`, { headers: { skip: "true" } })
        .subscribe(
          (dados: any) => {

            this.isLoading = false
            // this.isCEPinvalid = false

            if ('erro' in dados) {
              // this.isCEPinvalid = true
            } else {
              this.populaCEP(dados)

              let filtro = this.estados.filter(el => {
                return el.sigla === dados.uf
              })

              this.formataEstado(filtro[0].nome)
            }
          },
          err => {
            this.isLoading = false
          }
        )

    }

  }

  populaCEP(dados: any) {
    this.formEnderecoResponsavel.patchValue({
      endereco: this.abreviaLogradouro(dados.logradouro).substring(0, 50),
      bairro: dados.bairro.substring(0, 40),
      estado: dados.uf,
      cidade: dados.localidade
    })
  }

  alphaOnly(e: any) {
    let regex = new RegExp("^[a-zA-ZÀ-ž' ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  alphaAndNumerOnly(e: any) {
    let regex = new RegExp("^[A-Za-z0-9]*$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  abreviaLogradouro(texto: any) {

    let obj: any = {
      Avenida: "Av.",
      Professor: "Prof.",
      Professora: "Profa.",
      Doutor: "Dr.",
      Doutora: "Dra."
    }

    Object.keys(obj).forEach((key) => {
      texto = texto.replaceAll(key, obj[key])
    })
    return texto
  }
}
