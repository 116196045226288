<div class="k-container">
  <ul class="step-container">
    <li>
      <a [ngClass]="{ active: false }"><span class="number">1</span><span class="page"> {{ lang.one }} </span></a>
    </li>
    <li>
      <a
        [ngClass]="{
          active: step === '/cadastro-aluno' || step === '/cadastro-responsavel' || step === '/cadastro-responsavel-financeiro'
        }"><span class="number">2</span><span class="page"> {{ lang.two }} </span></a>
    </li>
    <li>
      <a [ngClass]="{ active: step === '/disciplinas' }"><span class="number">3</span><span class="page"> {{ lang.three }} </span></a>
    </li>
    <li>
      <a [ngClass]="{ active: step === '/demais-condicoes' }"><span class="number">4</span><span class="page"> {{ lang.four }} </span></a>
    </li>
    <li>
      <a [ngClass]="{ active: step === '/final' }"><span class="number">5</span><span class="page"> {{ lang.five }} </span></a>
    </li>
  </ul>
</div>