import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CountryList } from 'src/app/models/country-list.model'
import { ParentData, SessionType } from 'src/app/models/enrollment.model'
import { DocumentFormat, PhoneType } from 'src/app/models/format.model'
import { Address, Buttons, CadastroResponsavel, ErrorInput } from 'src/app/models/language.model'
import { ApisService } from 'src/app/services/apis.service'
import { CountryListService } from 'src/app/services/country-list.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { validCPF, validRUT } from '../../shared/valildators/form.validator'
import { ConfirmService } from './../../services/confirm.service'

@Component({
  selector: 'app-cadastro-responsavel-financeiro',
  templateUrl: './cadastro-responsavel-financeiro.component.html',
  styleUrls: ['./cadastro-responsavel-financeiro.component.scss']
})
export class CadastroResponsavelFinanceiroComponent implements OnInit {

  lang: CadastroResponsavel = this.language.cadastroResponsavel
  langEndereco: Address = this.language.endereco
  langButton: Buttons = this.language.buttons
  langInputError: ErrorInput = this.language.errorInput

  country = this.dados.getDados().countryId
  classeAtiva = "containerStepper-vertical"
  stepAtivo = 0
  isLoading = false
  isCEPinvalid = false
  setmesmoEnderecoAluno = false

  isSameGuardianValue!: boolean
  // dataSource!: DataSource

  relacaoAluno = this.language.relacaoAluno
  relacaoAlunoTipo = ''
  estados = this.language.estados
  estadoNome = ''

  displayForm = {
    cep: true,
    endereco: true,
    numero: true,
    complemento: true,
    bairro: true,
    estado: true,
    cidade: true
  }

  personalDocumentFormat: DocumentFormat = {
    documentMask: '000.000.000-00',
    documentInputmode: 'numeric',
    documentMaxlength: null
  }

  documentMaskRUT = ''

  phoneFormat = {
    mask: {
      telRes: '',
      telCom: '',
      telCel: ''
    },
    placeholder: {
      telRes: '',
      telCom: '',
      telCel: ''
    }
  }

  addressOrder = {
    cep: 0,
    endereco: 0,
    splitField: {
      order: 0,
      numero: 0,
      complemento: 0,
    },
    bairro: 0,
    estado: 0,
    cidade: 0
  }

  allCountries: CountryList[] = this.countryService.allCountries

  steps = [
    {
      'nome': 'step-1',
      'ativo': true
    },
    {
      'nome': 'step-2',
      'ativo': false
    },
    {
      'nome': 'step-3',
      'ativo': false
    }
  ];

  responsavelFinanceiro: ParentData = {
    relationship: '',
    firstName: '',
    middleName: '',
    lastName: '',
    personalDocument: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    home_phone: '',
    work_phone: '',
    cellphone: '',
    email: ''
  }

  customPatterns: any

  @ViewChild('stickyMenu') menuElement!: ElementRef
  sticky: boolean = false;
  elementPosition: any

  formDadosFinanceiro = new FormGroup({
    nome: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/[a-zA-ZÀ-ž]+\s+[a-zA-ZÀ-ž]+/)]),
    cpf: new FormControl('', [Validators.required, Validators.minLength(11), validCPF]),
    relacao: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    telRes: new FormControl(),
    telCom: new FormControl(),
    telCel: new FormControl({ value: '', disabled: true }),
    codeRes: new FormControl(),
    codeCom: new FormControl(),
    codeCel: new FormControl()
  })

  formEnderecoFinanceiro = new FormGroup({
    cep: new FormControl('', [Validators.required, Validators.minLength(8)]),
    endereco: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)]),
    numero: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z0-9]+/)]),
    complemento: new FormControl(''),
    bairro: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)]),
    estado: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)])
  })

  constructor(
    private router: Router,
    private apiService: ApisService,
    private confirm: ConfirmService,
    private http: HttpClient,
    private el: ElementRef,
    private dados: DadosService,
    private language: LanguageService,
    private countryService: CountryListService
  ) { }

  @HostListener('window:scroll', ['$event'])
  handleScroll(e: any) {
    const windowScroll = window.scrollY

    if (windowScroll >= this.elementPosition) {
      this.sticky = true
    } else {
      this.sticky = false
    }
  }

  @HostListener('window:resize', ['$event'])
  handleResize(e: any) {
    this.elementPosition = this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY

  }

  ngAfterViewInit() {
    this.elementPosition =
      this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY

    setTimeout(() => {
      this.elementPosition =
        this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY
    })

  }


  ngOnInit(): void {
    this.handleInitialData()
    this.changeFormByCountry()

    // let filterRelacao = this.relacaoAluno.filter((item: any) => item.valor != 1)

    // // // console.log(this.relacaoAluno)
    // // // console.log(filterRelacao)

    this.changePhoneFormat(
      this.dados.getDados().selectedPhoneFinance || this.country,
      ['telRes', 'telCom', 'telCel']
    )
  }

  async handleInitialData() {

    if (sessionStorage.getItem('dadosFinanceiro')) {

      this.getDadosFinanceiro()
      this.isSameGuardianValue = false

    } else if (sessionStorage.getItem('ksis')) {

      const { dados, endereco } = await this.getDadosKsis()

      this.formDadosFinanceiro.patchValue(dados)
      this.formEnderecoFinanceiro.patchValue(endereco)

      this.formDadosFinanceiro.enable()
      this.formEnderecoFinanceiro.enable()

      this.isSameGuardianValue = false

    } else {

      this.getDadosResponsavel()
      this.isSameGuardianValue = true
    }
  }

  async setSameGuardianValue() {

    if (this.isSameGuardianValue) {

      this.getDadosResponsavel()

    } else {

      if (sessionStorage.getItem('dadosFinanceiro')) {

        this.getDadosFinanceiro()

      } else if (sessionStorage.getItem('ksis')) {

        const { dados, endereco } = await this.getDadosKsis()

        this.formDadosFinanceiro.patchValue(dados)
        this.formEnderecoFinanceiro.patchValue(endereco)

        this.formDadosFinanceiro.enable()
        this.formEnderecoFinanceiro.enable()

      } else {
        this.resetForm()
      }

    }

  }

  getDadosFinanceiro() {
    this.getDataFromSession('dadosFinanceiro')
    this.formDadosFinanceiro.enable()
    this.formEnderecoFinanceiro.enable()
  }

  getDadosResponsavel() {
    this.getDataFromSession('dadosResponsavel')
    this.formDadosFinanceiro.disable()
    this.formEnderecoFinanceiro.disable()
  }

  getDadosKsis() {
    return new Promise<any>(resolve => {

      const { guardians } = JSON.parse(sessionStorage.getItem('ksis')!)

      const index = guardians.findIndex((item: any) => item.financeResponsibleFlag === true)

      const financeGuardian = index > 0
        ? guardians[index]
        : guardians[0]

      const filtroEstado = this.estados.filter((item: any) => financeGuardian.stateCode === item.sigla)

      const filtroRelacao = this.relacaoAluno.filter((item: { valor: number }) => financeGuardian.guardianType == item.valor)

      const financeiro = {
        dados: {
          nome: this.apiService.removeSpaces(`
            ${financeGuardian.firstName || ''}
            ${financeGuardian.middleName || ''}
            ${financeGuardian.lastName || ''}
            `),
          cpf: financeGuardian.officialDocNumber || '',
          relacao: filtroRelacao.length > 0 ? filtroRelacao[0].valor : '',
          email: this.apiService.removeSpaces(financeGuardian.email || ''),
          telRes: financeGuardian.phoneNumberHome || '',
          telCom: financeGuardian.phoneNumberOffice || '',
          telCel: financeGuardian.phoneNumberMobile || '',
          codeRes: this.dados.getDados().selectedPhoneFinance?.telRes?.dialCode
            ?? this.countryService.getInfoCountry(this.country).dialCode,
          codeCom: this.dados.getDados().selectedPhoneFinance?.telCom?.dialCode
            ?? this.countryService.getInfoCountry(this.country).dialCode,
          codeCel: this.dados.getDados().selectedPhoneFinance?.telCel?.dialCode
            ?? this.countryService.getInfoCountry(this.country).dialCode
        },
        endereco: {
          cep: this.apiService.removeSpaces(financeGuardian.zipCode || ''),
          endereco: this.apiService.removeSpaces(financeGuardian.address1 || ''),
          numero: this.apiService.removeSpaces(financeGuardian.address2) || '',
          complemento: this.apiService.removeSpaces(financeGuardian.address3 || ''),
          bairro: this.apiService.removeSpaces(financeGuardian.address4 || ''),
          estado: filtroEstado.length > 0 ? filtroEstado[0].sigla : '',
          cidade: this.apiService.removeSpaces(financeGuardian.city || '')
        }
      }

      this.filterOptions(financeiro.endereco, financeiro.dados)

      resolve(financeiro)
    })
  }

  mudaEtapa(id: number) {
    this.steps.forEach((el) => {
      el.ativo = false
    })

    this.steps[id].ativo = true

    window.scroll(0, 330)
  }

  proximaPagina() {

    if (this.validarParte()) {

      if (this.stepAtivo < this.steps.length - 1) {

        this.stepAtivo++
        this.mudaEtapa(this.stepAtivo)

      } else {

        let name = this.apiService.splitName(
          this.apiService.removeSpaces(this.formDadosFinanceiro.getRawValue().nome, true)
        )

        this.responsavelFinanceiro = {
          relationship: this.formDadosFinanceiro.getRawValue().relacao,
          firstName: name.firstName,
          middleName: name.middleName,
          lastName: name.lastName,
          personalDocument: this.apiService.removeSpaces(this.formDadosFinanceiro.value.cpf.replace(/\.|-/g, '')),
          address1: this.apiService.removeSpaces(this.formEnderecoFinanceiro.getRawValue().endereco),
          address2: this.apiService.removeSpaces(this.formEnderecoFinanceiro.getRawValue().numero),
          address3: this.apiService.removeSpaces(this.formEnderecoFinanceiro.getRawValue().complemento),
          address4: this.apiService.removeSpaces(this.formEnderecoFinanceiro.getRawValue().bairro),
          city: this.apiService.removeSpaces(this.formEnderecoFinanceiro.getRawValue().cidade),
          state: this.formEnderecoFinanceiro.getRawValue().estado,
          postalCode: this.formEnderecoFinanceiro.getRawValue().cep,
          country: this.dados.getDados().countryId,
          home_phone: !this.formDadosFinanceiro.value.telRes ?
            '' :
            this.formDadosFinanceiro.value.codeRes + this.formDadosFinanceiro.getRawValue().telRes,
          work_phone: !this.formDadosFinanceiro.value.telCom ?
            '' :
            this.formDadosFinanceiro.value.codeCom + this.formDadosFinanceiro.getRawValue().telCom,
          cellphone: this.formDadosFinanceiro.getRawValue().telCel,
          email: this.apiService.removeSpaces(this.formDadosFinanceiro.getRawValue().email)
        }

        let confirm
        const parentData = this.confirm.getConfirm().parentData

        if (this.isSameGuardianValue) {
          confirm = [
            {
              ...parentData[0],
              financeResponsibleFlag: true,
              educationalAndFinanceResponsible: true
            }
          ]

        } else {

          confirm = [
            {
              ...parentData[0],
              financeResponsibleFlag: false,
              educationalAndFinanceResponsible: false
            },
            {
              ...this.responsavelFinanceiro,
              financeResponsibleFlag: true,
              educationalAndFinanceResponsible: false
            }
          ]

        }

        this.confirm.setConfirm({ parentData: confirm })

        // // console.log(confirm)
        // // console.log(this.isSameGuardianValue)

        let dadosFinanceiro = [this.formDadosFinanceiro.getRawValue(), this.formEnderecoFinanceiro.getRawValue()]

        sessionStorage.setItem("dadosFinanceiro", JSON.stringify(dadosFinanceiro))

        this.router.navigate(['/disciplinas'])
      }
    }
  }

  validarParte() {

    if (this.isSameGuardianValue) {
      // this.router.navigate(['/disciplinas'])
      this.stepAtivo = 2
      return true
    }

    if (this.stepAtivo == 0) {
      if (!this.formDadosFinanceiro.valid) {
        this.setFocus(this.formDadosFinanceiro.controls)
        this.formDadosFinanceiro.markAllAsTouched()
        return false
      }
    } else {
      if (!this.formEnderecoFinanceiro.valid) {
        this.setFocus(this.formEnderecoFinanceiro.controls)
        this.formEnderecoFinanceiro.markAllAsTouched()
        return false
      }
    }

    this.documentMaskRUT = this.formDadosFinanceiro.value.cpf.length === 8
      ? '0.000.000-A'
      : '00.000.000-A'

    return true
  }

  setFocus(formControl: any) {
    for (const key of Object.keys(formControl)) {
      if (formControl[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formControlName="' + key + '"]')
        invalidControl.focus()
        break
      }
    }
  }

  paginaAnterior() {
    if (this.stepAtivo != 0) {
      this.stepAtivo--
      this.mudaEtapa(this.stepAtivo)
    } else {
      this.router.navigate(['cadastro-responsavel'])
    }
  }

  getDataFromSession(sessionType: SessionType) {

    // const dados = sessionStorage.getItem(sessionType)!

    const sessionData = JSON.parse(sessionStorage.getItem(sessionType)!)
    const formDados = Object.keys(this.formDadosFinanceiro.getRawValue())
    const formEndereco = Object.keys(this.formEnderecoFinanceiro.getRawValue())

    formDados.forEach(el => {

      if (el in sessionData[0]) {
        return this.formDadosFinanceiro.patchValue(
          {
            ...sessionData[0],
          }
        )
      }
    })

    formEndereco.forEach(el => {
      if (el in sessionData[1]) {
        return this.formEnderecoFinanceiro.patchValue(
          {
            ...sessionData[1]
          }
        )
      }
    })

    this.formDadosFinanceiro.controls['nome'].disable()
    this.formDadosFinanceiro.controls['email'].disable()

    this.filterOptions(this.formEnderecoFinanceiro.getRawValue(), this.formDadosFinanceiro.getRawValue())



  }

  filterOptions(addressValue: any, dataValue: any) {

    const filtroEstado = this.estados.filter(item => addressValue.estado === item.sigla)

    const filtroRelacao = this.relacaoAluno.filter((item: { valor: number }) => Number(dataValue.relacao) === item.valor)

    const estado = filtroEstado.length > 0 ? filtroEstado[0].nome : ''
    const relacao = filtroRelacao.length > 0 ? filtroRelacao[0].tipo : ''

    if (!this.formEnderecoFinanceiro.controls.cep.disabled) this.formataEstado(estado)

    this.formataRelacao(relacao)
  }

  onSelectCountry(isoCode: any, phoneType: PhoneType) {

    let selectedPhoneFinance = {
      ...this.dados.getDados().selectedPhoneFinance,
      [phoneType]: {
        isoCode: this.countryService.getInfoCountry(isoCode).isoCode,
        dialCode: this.countryService.getInfoCountry(isoCode).dialCode,
      }
    }

    this.formDadosFinanceiro.patchValue({ [phoneType]: '' })

    this.changePhoneFormat(selectedPhoneFinance, [phoneType])

    this.dados.setDados({ selectedPhoneFinance })

  }

  changePhoneFormat(isoCode: any, phoneType: Array<string>) {


    let selected: any, mask, placeholder, minLength, phoneKey

    phoneType.forEach(item => {

      phoneKey = isoCode[item] ? isoCode[item].isoCode : this.country

      selected = typeof isoCode === 'string'
        ? this.countryService.getInfoCountry(isoCode)
        : this.countryService.getInfoCountry(phoneKey)

      for (const key in this.phoneFormat.mask) {

        if (key === item) {

          // // console.log('selectedPhoneFinance[key]', isoCode[key])

          if (item === 'telCel') {

            mask = selected.cellphone.format
            placeholder = selected.cellphone.placehoder
            minLength = selected.cellphone.minLength
            this.formDadosFinanceiro.get(key)?.setValidators([Validators.required, Validators.minLength(minLength)])

          } else {

            mask = selected.homePhone.format
            placeholder = selected.homePhone.placehoder
            minLength = selected.homePhone.minLength
            this.formDadosFinanceiro.get(key)?.setValidators([Validators.minLength(minLength)])

          }

          this.phoneFormat.mask[key as PhoneType] = mask
          this.phoneFormat.placeholder[key as PhoneType] = placeholder
          this.formDadosFinanceiro.get(key)?.updateValueAndValidity()
        }

      }

    })

  }

  changeFormByCountry() {

    switch (this.country) {

      case 'CL':
        this.personalDocumentFormat = {
          documentMask: '0.000.000-A||00.000.000-A',
          documentInputmode: 'text',
          documentMaxlength: null,
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          estado: false
        }

        this.formDadosFinanceiro.get('cpf')?.setValidators([Validators.required, validRUT])

        this.formDadosFinanceiro.updateValueAndValidity()
        this.formEnderecoFinanceiro.updateValueAndValidity()

        this.formEnderecoFinanceiro.controls['cep'].disable()
        this.formEnderecoFinanceiro.controls['estado'].disable()

        this.formEnderecoFinanceiro.patchValue({
          estado: 'CL'
        })

        break

      case 'CO':

        //this.customPatterns = { 'A': { pattern: new RegExp("^[A-Za-z0-9]*$"), optional: true } }

        this.personalDocumentFormat = {
          documentMask: "9999999999",
          documentInputmode: 'numeric',
          documentMaxlength: '10'
        }

        this.displayForm = {
          ...this.displayForm,
          numero: false,
          cep: false,
          estado: false
        }

        this.addressOrder = {
          ...this.addressOrder,
          cidade: 1,
          bairro: 2,
          endereco: 3,
          splitField: {
            order: 4,
            numero: 5,
            complemento: 6,
          },
        }

        this.formDadosFinanceiro.get('cpf')?.setValidators([Validators.required, Validators.minLength(1)])

        this.formDadosFinanceiro.updateValueAndValidity()
        this.formEnderecoFinanceiro.updateValueAndValidity()

        this.formEnderecoFinanceiro.controls['numero'].disable()
        this.formEnderecoFinanceiro.controls['cep'].disable()
        this.formEnderecoFinanceiro.controls['estado'].disable()

        this.formEnderecoFinanceiro.patchValue({
          estado: 'CO'
        })

        break

      case 'AR':
        this.personalDocumentFormat = {
          documentMask: '00.000.000',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          estado: false
        }

        this.formDadosFinanceiro.get('cpf')?.setValidators([Validators.required, Validators.minLength(8)])

        this.formDadosFinanceiro.updateValueAndValidity()
        this.formEnderecoFinanceiro.updateValueAndValidity()

        this.formEnderecoFinanceiro.controls['cep'].disable()
        this.formEnderecoFinanceiro.controls['estado'].disable()

        this.formEnderecoFinanceiro.patchValue({
          estado: 'AR'
        })

        break


      case 'BO':
        this.personalDocumentFormat = {
          documentMask: '99999999999',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false
        }

        this.formDadosFinanceiro.get('cpf')?.setValidators([Validators.required, Validators.minLength(7)])

        this.formDadosFinanceiro.updateValueAndValidity()
        this.formEnderecoFinanceiro.updateValueAndValidity()

        this.formEnderecoFinanceiro.controls['cep'].disable()

        break

      case 'PE':
        this.personalDocumentFormat = {
          documentMask: '00000000',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false
        }

        this.formDadosFinanceiro.get('cpf')?.setValidators([Validators.required, Validators.minLength(8)])

        this.formDadosFinanceiro.updateValueAndValidity()
        this.formEnderecoFinanceiro.updateValueAndValidity()

        this.formEnderecoFinanceiro.controls['cep'].disable()

        break

      case 'UY':
        this.personalDocumentFormat = {
          documentMask: '0.000.000-0',
          documentInputmode: 'numeric',
          documentMaxlength: null
        }

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          bairro: false
        }

        this.formDadosFinanceiro.get('cpf')?.setValidators([Validators.required, Validators.minLength(8)])

        this.formDadosFinanceiro.updateValueAndValidity()
        this.formEnderecoFinanceiro.updateValueAndValidity()

        this.formEnderecoFinanceiro.controls['cep'].disable()
        this.formEnderecoFinanceiro.controls['bairro'].disable()

        break

    }
  }

  formataRelacao(option: any) {
    this.relacaoAlunoTipo = option
  }

  formataEstado(option: any) {
    this.estadoNome = option
  }

  mesmoEndereco() {
    let enderecoAluno = JSON.parse(sessionStorage.getItem("dadosAluno") || '{}')

    this.formEnderecoFinanceiro.setValue({
      cep: enderecoAluno[1].cep,
      endereco: enderecoAluno[1].endereco,
      numero: enderecoAluno[1].numero,
      complemento: enderecoAluno[1].complemento,
      bairro: enderecoAluno[1].bairro,
      estado: enderecoAluno[1].estado,
      cidade: enderecoAluno[1].cidade,
    })

    let filtro = this.estados.filter(el => {
      return el.sigla === enderecoAluno[1].estado
    })

    if (!this.formEnderecoFinanceiro.get('estado')?.disabled) {
      this.formataEstado(filtro[0].nome)
    }

  }

  consultaCEP(e: any) {

    let cep = this.formEnderecoFinanceiro.get('cep')?.value

    if (cep != '' && cep.length === 8) {

      this.isLoading = true

      this.http.get(`//viacep.com.br/ws/${cep}/json`, { headers: { skip: "true" } })
        .subscribe(
          (dados: any) => {

            this.isLoading = false
            // this.isCEPinvalid = false

            if ('erro' in dados) {
              // this.isCEPinvalid = true
            } else {
              this.populaCEP(dados)

              let filtro = this.estados.filter(el => {
                return el.sigla === dados.uf
              })

              this.formataEstado(filtro[0].nome)
            }
          },
          err => {
            this.isLoading = false
          }
        )

    }

  }

  populaCEP(dados: any) {
    this.formEnderecoFinanceiro.patchValue({
      endereco: this.abreviaLogradouro(dados.logradouro).substring(0, 50),
      bairro: dados.bairro.substring(0, 40),
      estado: dados.uf,
      cidade: dados.localidade
    })
  }

  alphaOnly(e: any) {
    let regex = new RegExp("^[a-zA-ZÀ-ž' ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  alphaAndNumerOnly(e: any) {
    let regex = new RegExp("^[A-Za-z0-9]*$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  abreviaLogradouro(texto: any) {

    let obj: any = {
      Avenida: "Av.",
      Professor: "Prof.",
      Professora: "Profa.",
      Doutor: "Dr.",
      Doutora: "Dra."
    }

    Object.keys(obj).forEach((key) => {
      texto = texto.replaceAll(key, obj[key])
    })
    return texto
  }

  resetForm() {

    let formDados = Object.keys(this.formDadosFinanceiro.value)
    let formEndereco = Object.keys(this.formEnderecoFinanceiro.value)

    const newFormDados = formDados.reduce((obj, key) => {
      return {
        ...obj,
        [key]: '',
        codeCom: this.countryService.getInfoCountry(this.country).dialCode,
        codeRes: this.countryService.getInfoCountry(this.country).dialCode,
        codeCel: this.countryService.getInfoCountry(this.country).dialCode,
      }

    }, {})

    const newFormEndereco = formEndereco.reduce((obj, key) => {
      return {
        ...obj,
        [key]: '',
      }

    }, {})

    this.formDadosFinanceiro.patchValue(newFormDados)
    this.formEnderecoFinanceiro.patchValue(newFormEndereco)

    this.formDadosFinanceiro.enable()
    this.formEnderecoFinanceiro.enable()

  }
}
