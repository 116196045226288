import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from './../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private appSecretKey: string = environment.appSecretKey;

  private readonly api = `${environment.API}/Authentication`
  private readonly api_consent = `${environment.APIconsent}/Authentication`

  user = {
    username: environment.username,
    password: environment.password,
  }

  constructor(
    private http: HttpClient,
  ) { }

  params = new HttpParams().append('appSecretKey', this.appSecretKey)


  auth(): Observable<any> {

    return (
      this.http.post<string>(`${this.api}/login`, this.user, { params: this.params }))
  }


  whoAmI(): Observable<any> {
    return this.http.get<string>(`${this.api}/whoami`)
  }


  authConsent(): Observable<any> {
    return (
      this.http.post<string>(`${this.api_consent}/login`, this.user, { params: this.params })
    )
  }
}
