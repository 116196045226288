import { Injectable } from '@angular/core'
import { LanguageService } from './language.service'

@Injectable({
  providedIn: 'root'
})
export class CountryListService {

  constructor(
    private languageService: LanguageService
  ) { }



  get allCountries() {
    return [
      {
        "name": "Argentina",
        "dialCode": "+54",
        "isoCode": "AR",
        "flag": "assets/flags-icon-svg/ar.svg",
        "cellphone": {
          "format": "9999999999",
          "minLength": 5,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        },
        "homePhone": {
          "format": "9999999999",
          "minLength": 5,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        }
      },
      {
        "name": "Bolivia",
        "dialCode": "+591",
        "isoCode": "BO",
        "flag": "assets/flags-icon-svg/bo.svg",
        "cellphone": {
          "format": "9999999999",
          "minLength": 7,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        },
        "homePhone": {
          "format": "9999999999",
          "minLength": 7,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        }
      },
      {
        "name": "Brasil",
        "dialCode": "+55",
        "isoCode": "BR",
        "flag": "assets/flags-icon-svg/br.svg",
        "cellphone": {
          "format": "(00) 00000-0000",
          "minLength": 11,
          "placehoder": "(00) 00000-0000"
        },
        "homePhone": {
          "format": "(00) 0000-0000",
          "minLength": 10,
          "placehoder": "(00) 0000-0000"
        }
      },
      {
        "name": "Chile",
        "dialCode": "+56",
        "isoCode": "CL",
        "flag": "assets/flags-icon-svg/cl.svg",
        "cellphone": {
          "format": "0 0000-0000",
          "minLength": 9,
          "placehoder": "0 0000-0000"
        },
        "homePhone": {
          "format": "0 0000-0000",
          "minLength": 9,
          "placehoder": "0 0000-0000"
        }
      },
      {
        "name": "Colombia",
        "dialCode": "+57",
        "isoCode": "CO",
        "flag": "assets/flags-icon-svg/co.svg",
        "cellphone": {
          "format": "(000) 0000000",
          "minLength": 10,
          "placehoder": "(000) 0000000"
        },
        "homePhone": {
          "format": "(000) 0000000",
          "minLength": 10,
          "placehoder": "(000) 0000000"
        }
      },
      {
        "name": "Perú",
        "dialCode": "+51",
        "isoCode": "PE",
        "flag": "assets/flags-icon-svg/pe.svg",
        "cellphone": {
          "format": "9999999999",
          "minLength": 7,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        },
        "homePhone": {
          "format": "9999999999",
          "minLength": 7,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        }
      },
      {
        "name": "Uruguay",
        "dialCode": "+598",
        "isoCode": "UY",
        "flag": "assets/flags-icon-svg/uy.svg",
        "cellphone": {
          "format": "9999999999",
          "minLength": 5,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        },
        "homePhone": {
          "format": "9999999999",
          "minLength": 5,
          "placehoder": this.languageService.selectCountryPhone.placehoderKSACountry
        }
      },
      {
        "name": this.languageService.selectCountryPhone.selectOtherCountry,
        "dialCode": "+",
        "isoCode": "O",
        "flag": null,
        "cellphone": {
          "format": "99999999999999",
          "minLength": 5,
          "placehoder": this.languageService.selectCountryPhone.placehoderOtherCountry
        },
        "homePhone": {
          "format": "99999999999999",
          "minLength": 5,
          "placehoder": this.languageService.selectCountryPhone.placehoderOtherCountry
        }
      }
    ]
  }


  getInfoCountry(country: string) {
    let i = this.allCountries.findIndex(item => item.isoCode === country)

    return this.allCountries[i]
  }



}
