import { Router } from '@angular/router'
import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ApisService } from 'src/app/services/apis.service'
import { ConfirmService } from 'src/app/services/confirm.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { Buttons, DemaisCondicoes, ErrorMessage } from 'src/app/models/language.model'

@Component({
  selector: 'app-demais-condicoes',
  templateUrl: './demais-condicoes.component.html',
  styleUrls: ['./demais-condicoes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DemaisCondicoesComponent implements OnInit {

  lang: DemaisCondicoes = this.language.demaisCondicoes
  langMessage: ErrorMessage = this.language.errorMessage
  langButton: Buttons = this.language.buttons

  isLoading = false
  isConfirm = true
  textoCondicoes = ''
  colorIcon = 'error'
  icon = 'fas fa-exclamation'
  tituloMensagem = this.langMessage.titleMessageError
  textoMensagem = this.langMessage.textMessageError
  mostraBotaoTentarNovamente = true

  confirmDados: any

  constructor(
    private router: Router,
    private apiService: ApisService,
    private confirm: ConfirmService,
    private dados: DadosService,
    private language: LanguageService
  ) { }

  ngOnInit(): void {
    this.getConditions()

    if (this.confirm.hasConfirm()) {
      this.confirmDados = this.confirm.getConfirm()
    }

  }

  getConditions() {

    this.isLoading = true
    this.isConfirm = true

    this.apiService.condicoesMatricula(this.dados.getDados().countryId).subscribe(
      res => {
        this.textoCondicoes = res.contentData
        this.isLoading = false

        console.log(res)
      },
      err => {
        console.log(err)
        this.isConfirm = false
        this.isLoading = false
        // this.textoMensagem = this.language.apiError(err.error)
        this.textoMensagem
      }
    )
  }

  proximaPagina() {

    this.isLoading = true

    this.apiService.confirmEnrollment(this.confirmDados, this.dados.getDados().hash).subscribe(
      res => {

        console.log(res)

        let { referenceCode, nextHash } = res

        sessionStorage.setItem('nextHash', JSON.stringify({ nextHash: nextHash }))

        if (referenceCode !== undefined || referenceCode !== '') {
          this.dados.setDados({
            referenceCode
          })

          this.router.navigate(['/final'])
        }

      },
      err => {

        console.log('err', err)

        if (err.status === 400) {

          this.isConfirm = false
          this.mostraBotaoTentarNovamente = false
          this.colorIcon = 'success'
          this.icon = 'fas fa-check'
          this.tituloMensagem = this.langMessage.titleMessageEnrollmentComplete
          this.textoMensagem = this.langMessage.textMessageEnrollmentComplete
        }
        else if (err.status === 500) {

          this.textoMensagem = this.language.apiError(err.error)
          this.isConfirm = false
          this.mostraBotaoTentarNovamente = true

        }
        else {

          this.isConfirm = false
          this.textoMensagem = this.language.apiError(err.error)
        }

        this.isLoading = false

      }
    )


  }

  voltaPagina() {
    this.router.navigate(['/disciplinas']);
  }

  fecharErro() {
    this.getConditions()
  }

}
