export const environment = {
    production: false,
    API: "https://eenrollmentapitst.kumon.com.br/api",
    username: "eEnrollment",
    password: "VtB+ZxDJTsUFa7j_e-EL5kv6#NXpYR",
    appSecretKey: "Mve|gsxKmuB7j4yTEa*SD-h0t:LkUd6rlQ.pCcGPJWq#8",

    APIconsent: 'https://econsentapitst.kumon.com.br/api',
    URLConsent: 'https://econsenttst.kumon.com.br',
    URLConsentLA: 'https://econsenttst.kumonla.com'
};
