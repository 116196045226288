import { Component, Inject, OnInit } from '@angular/core'
import { Buttons, Final } from 'src/app/models/language.model'
import { ConfirmService } from 'src/app/services/confirm.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { environment } from './../../../environments/environment'
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss']
})
export class FinalComponent implements OnInit {

  lang: Final = this.language.final
  langButton: Buttons = this.language.buttons

  nomeAluno = ''
  protocolo = ''
  hasNextHash = true
  nextHash = ''

  country = ''

  constructor(
    private dados: DadosService,
    private confirm: ConfirmService,
    private language: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.getAlunoNome()
    this.verificaNextHash()

    this.country = this.dados.getDados().countryId

    console.log('country', this.country)
  }

  getAlunoNome() {
    if (!this.confirm.hasConfirm()) return

    this.nomeAluno = JSON.parse(sessionStorage.getItem('dadosAluno')!)[0].nome

    this.protocolo = this.dados.getDados().referenceCode
  }

  verificaNextHash() {

    this.nextHash = JSON.parse(sessionStorage.getItem('nextHash') || '').nextHash

    console.log('nexthash', this.nextHash)

    if (this.nextHash === '' || this.nextHash === undefined) {
      this.hasNextHash = false
    }
  }

  novaMatricula(e: Event) {

    e.preventDefault()

    sessionStorage.removeItem("enrollmentToken")
    sessionStorage.removeItem("confirm")
    sessionStorage.removeItem("consentToken")
    sessionStorage.removeItem("UserLogged")
    sessionStorage.removeItem("dadosAluno")
    sessionStorage.removeItem("enrollmentData")
    

    return this.country === 'BR'
      ? this.document.location.replace(`${environment.URLConsent}/?hash=${this.nextHash}`)
      : this.document.location.replace(`${environment.URLConsentLA}/?hash=${this.nextHash}`)

  }
}
