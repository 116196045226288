import { DemaisCondicoesComponent } from './pages/demais-condicoes/demais-condicoes.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guards/auth.guard'

import { CadastroAlunoComponent } from './pages/cadastro-aluno/cadastro-aluno.component'
import { CadastroResponsavelComponent } from './pages/cadastro-responsavel/cadastro-responsavel.component'
import { DisciplinasComponent } from './pages/disciplinas/disciplinas.component'
import { FinalComponent } from './pages/final/final.component'
import { HomeComponent } from './pages/home/home.component'
import { CadastroResponsavelFinanceiroComponent } from './pages/cadastro-responsavel-financeiro/cadastro-responsavel-financeiro.component'
import { CheckComponent } from './pages/check/check.component'

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'cadastro-aluno', component: CadastroAlunoComponent, canActivate: [AuthGuard] },
  { path: 'cadastro-responsavel', component: CadastroResponsavelComponent, canActivate: [AuthGuard] },
  { path: 'cadastro-responsavel-financeiro', component: CadastroResponsavelFinanceiroComponent, canActivate: [AuthGuard] },
  { path: 'disciplinas', component: DisciplinasComponent, canActivate: [AuthGuard] },
  { path: 'demais-condicoes', component: DemaisCondicoesComponent, canActivate: [AuthGuard] },
  { path: 'final', component: FinalComponent, canActivate: [AuthGuard] },
  { path: 'check', component: CheckComponent },
  { path: '**', redirectTo: '' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
