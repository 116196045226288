import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() id: any
  @Input() titulo: string = ''
  @Input() size: string = ''
  @Input() positionY: string = ''
  @Input() corpo: string = ''
  @Input() btnFechar: string = 'Fechar'
  @Input() btnSalvar: string = 'Salvar'
  @Input() hasBtnSalvar: boolean = false
  
  constructor() { }

  ngOnInit(): void {
  }

}
