import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'

import { NgxMaskModule } from 'ngx-mask'
import { CalendarModule } from 'primeng/calendar'
import { AppComponent } from './app.component'

import { AuthenticationInterceptor } from './authentication/authentication.interceptor'
import { CadastroAlunoComponent } from './pages/cadastro-aluno/cadastro-aluno.component'
import { CadastroResponsavelComponent } from './pages/cadastro-responsavel/cadastro-responsavel.component'
import { DemaisCondicoesComponent } from './pages/demais-condicoes/demais-condicoes.component'
import { DisciplinasComponent } from './pages/disciplinas/disciplinas.component'
import { FinalComponent } from './pages/final/final.component'
import { HomeComponent } from './pages/home/home.component'
import { HideEmailPipe } from './pipes/hide-email.pipe'
import { PhonePipe } from './pipes/phone.pipe'
import { TimeFormatPipe } from './pipes/time-format.pipe'
import { HeaderComponent } from './shared/header/header.component'
import { InputErrorComponent } from './shared/input-error/input-error.component'
import { LoadingComponent } from './shared/loading/loading.component'
import { MessageComponent } from './shared/message/message.component'
import { ModalComponent } from './shared/modal/modal.component'
import { StepComponent } from './shared/step/step.component'

import { NgSelectModule } from '@ng-select/ng-select'
import { CadastroResponsavelFinanceiroComponent } from './pages/cadastro-responsavel-financeiro/cadastro-responsavel-financeiro.component'
import { CheckComponent } from './pages/check/check.component'
import { CepPipe } from './pipes/cep.pipe'
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe'
import { HelpButtonComponent } from './shared/help-button/help-button.component'
import { LoadingInnerComponent } from './shared/loading-inner/loading-inner.component'

// export function initializeEnrollment(initService: InitService) {
//   return (): Promise<any> => {
//     return initService.Init()
//   }
// }

// export function initializeConsent(InitServiceConsent: InitServiceConsent) {
//   return (): Promise<any> => {
//     return InitServiceConsent.Init()
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CadastroAlunoComponent,
    CadastroResponsavelComponent,
    DisciplinasComponent,
    FinalComponent,
    HideEmailPipe,
    InputErrorComponent,
    LoadingComponent,
    MessageComponent,
    ModalComponent,
    StepComponent,
    PhonePipe,
    TimeFormatPipe,
    DemaisCondicoesComponent,
    CepPipe,
    LoadingInnerComponent,
    SanitizeHtmlPipe,
    HelpButtonComponent,
    CadastroResponsavelFinanceiroComponent,
    CheckComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    CalendarModule
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeEnrollment,
    //   deps: [InitService, InitServiceConsent],
    //   multi: true
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeConsent,
    //   deps: [InitServiceConsent, InitService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-NCF862D' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
