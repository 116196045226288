
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { consent } from '../authentication/authentication.interceptor'
import { environment } from './../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ApisService {

  hash: string = ''
  validHashCode: boolean = true

  private readonly url_Enrollment = `${environment.API}/Enrollment`;
  private readonly url_Lead = `${environment.API}`;
  private readonly url_Consent = `${environment.APIconsent}/Consent`;
  private readonly url_Docs = `${environment.APIconsent}/Docs`;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.getHashCode()
  }

  getHashCode() {
    this.route.queryParams.subscribe(
      param => {

        if (param.hash === undefined || param.hash === '') {
          this.validHashCode = false
        } else {
          this.hash = param.hash
        }

        return param.hash
      }
    )
  }

  // newEnrollment(): Observable<any> {
  //   return this.http.get(`${this.url_Enrollment}/new/${this.country}/${this.hash}`)
  // }

  newEnrollment(): Observable<any> {
    return this.http.get(`${this.url_Enrollment}/new/${this.hash}`)
  }

  getKsisData(country: string): Observable<any> {
    return this.http.get(`${this.url_Enrollment}/ksisdata/${country}/${this.hash}`)
  }

  email(user: any): Observable<any> {
    return this.http.post(`${this.url_Consent}/email`, user, { context: consent() }).pipe(
      map(data => {
        if (data) {
          sessionStorage.setItem('UserLogged', JSON.stringify(data))
        }
        return data

      })
    )
  }

  isUserLoggedIn() {
    return !!sessionStorage.getItem('UserLogged')
  }

  // conditions(country: string): Observable<any> {
  //   return this.http.get(`${this.url_Enrollment}/conditions/${country}`)
  // }

  classSubjects(centerId: string, countryId: string): Observable<any> {

    let params = { centerId, countryId }

    return this.http.get(`${this.url_Enrollment}/classsubjects`, { params })
  }

  confirmEnrollment(dados: any, hash: string): Observable<any> {
    return this.http.post(`${this.url_Enrollment}/confirm/${hash}`, dados)
  }

  cadastraLead(body: any): Observable<any> {
    return this.http.post(`${this.url_Lead}/Lead`, body)
  }

  condicoesMatricula(countryId: string): Observable<any> {
    return this.http.get(`${this.url_Docs}/condicoesmatricula`, {
      context: consent(),
      params: { countryId }
    })
  }

  splitName(value: string) {
    let fullName = value.split(' ')
    let regex = /(filho|fo|f)$|(junior|júnior|jr)$|(neto|nt)$|(sobrinho|sob)$/i

    let dados = {
      firstName: fullName[0],
      middleName: '',
      lastName: ''
    }

    if (regex.test(value)) {
      dados.middleName = fullName.slice(1, -2).join(' ')
      dados.lastName = fullName.slice(-2).join(' ')
    } else {
      dados.middleName = fullName.slice(1, -1).join(' ')
      dados.lastName = fullName.slice(-1).join(' ')
    }

    return dados
  }

  removeSpaces(text: string, removeDot = false) {

    if (!text) return ''

    let result

    if (removeDot) {
      result = text.replace(/\.+/g, " ")
      result = result.trim().replace(/\s+/g, " ")
    } else {
      result = text.trim().replace(/\s+/g, " ")
    }

    return result
  }
}
