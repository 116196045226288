<app-loading *ngIf="isLoading; else conteudo"></app-loading>

<ng-template #conteudo [ngSwitch]="isDisciplinasLoaded">
  <app-step></app-step>

  <div class="k-container">
    <div class="principal">
      <div *ngSwitchCase="true">
        <h4 class="titulo">{{ lang.title }}</h4>

        <form #f="ngForm" (ngSubmit)="proximaPagina(f)">
          <h4 class="textos">
            {{ lang.labelClassSubjects }}
            <span> {{ lang.HighlightLabelClassSubjects }} </span>
          </h4>

          <div class="check-container check-container__column">
            <label
              class="check"
              *ngFor="let subject of classSubjects; let i = index">
              <input
                type="checkbox"
                [id]="'subject-' + subject.classSubjectID"
                [name]="'subject-' + subject.classSubjectID"
                [value]="subject.classSubjectID"
                (change)="checkDisciplina($event)"
                ngModel />
              <span class="selector"></span>
              <span> {{ subject.classSubject }} </span>
            </label>
          </div>

          <!-- Calendário -->
          <div *ngIf="mostrarCalendario">
            <h4 class="textos">Calendario</h4>
            <div class="check-container">
              <label class="radio" *ngFor="let calendario of calendarios">
                <input
                  type="radio"
                  [id]="'calendario' + calendario.id"
                  name="calendario"
                  [value]="calendario.id"
                  [(ngModel)]="valorCalendario"
                  required />
                <span class="selector"></span>
                <span>{{ calendario.label }}</span>
              </label>
            </div>
          </div>

          <h4 class="textos">{{ lang.labelTransfer }}</h4>

          <div class="check-container">
            <label class="check">
              <input
                type="checkbox"
                (change)="checkOutraUnidade($event)"
                [checked]="setCheckedUnidade" />
              <span class="selector"></span>
              <span> {{ lang.inputTransfer }} </span>
            </label>
          </div>


          <h4 class="textos">{{ lang.labelHopeDate }}</h4>
          <p-calendar
            name="enrollHopeDate"
            class="enrollHopeDate"
            [required]="true"
            dateFormat="dd/mm/yy"
            [readonlyInput]="true"
            [(ngModel)]="value"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placeholder]="lang.placeholderHopeDate"
            (ngModelChange)="onSelectDate($event)">
          </p-calendar>


          <div class="navigate-button">
            <button class="botaoVoltar" (click)="paginaAnterior()">
              <i class="fas fa-arrow-alt-circle-left"></i>
              <span> {{ langButton.back }} </span>
            </button>
            <button
              class="botaoContinuar"
              type="submit"
              [disabled]="botaoAtivo || f.invalid">
              <span> {{ langButton.continue }} </span>
              <i class="fas fa-arrow-alt-circle-right"></i>
            </button>
          </div>
        </form>
      </div>

      <app-message
        *ngSwitchCase="false"
        icon="fas fa-exclamation"
        colorIcon="error"
        [titulo]="langMessage.titleMessageError"
        class="containerMessageError">
        <p>{{ txtDadosErrados }}</p>
        <button type="button" class="btnMain" (click)="fecharErro()">
          {{ langButton.tryAgain }}
        </button>
      </app-message>
    </div>
  </div>

  <app-help-button *ngSwitchCase="true"></app-help-button>
</ng-template>