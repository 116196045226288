import { Injectable } from '@angular/core';

const KEY = 'enrollmentData'

@Injectable({
  providedIn: 'root'
})
export class DadosService {

  constructor() {
  }


  hasDados(){
    return !!this.getDados();
  }

  setDados(obj: any){

    if (!this.hasDados()) {
      sessionStorage.setItem(KEY, JSON.stringify(obj))
    } else {
      sessionStorage.setItem(KEY, JSON.stringify(Object.assign(this.getDados(), obj)))
    }
  }

  getDados(){
    const data = sessionStorage.getItem(KEY)
    return JSON.parse(data!)
  }

  removeDados(){
    sessionStorage.removeItem(KEY)
  }

}
