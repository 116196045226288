import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as bootstrap from 'bootstrap'
import { Modal } from 'bootstrap'
import * as moment from 'moment'
import { CountryList } from 'src/app/models/country-list.model'
import { ChildData } from 'src/app/models/enrollment.model'
import { Address, Buttons, CadastroAluno, ErrorInput, Prefix } from 'src/app/models/language.model'
import { ApisService } from 'src/app/services/apis.service'
import { CountryListService } from 'src/app/services/country-list.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { futureDate, invalidDate, minDate, pastDate } from '../../shared/valildators/form.validator'
import { ConfirmService } from './../../services/confirm.service'
@Component({
  selector: 'app-cadastro-aluno',
  templateUrl: './cadastro-aluno.component.html',
  styleUrls: ['./cadastro-aluno.component.scss']
})
export class CadastroAlunoComponent implements OnInit {

  lang: CadastroAluno = this.language.cadastroAluno
  langEndereco: Address = this.language.endereco
  langButton: Buttons = this.language.buttons
  langInputError: ErrorInput = this.language.errorInput

  country = this.dados.getDados().countryId
  prefixo: Prefix = this.language.prefixo

  classeAtiva = "containerStepper-vertical"
  stepAtivo = 0
  placeholderDate = 'dd/mm/aaaa'
  modal: Modal | undefined
  idModal = 'comoPreencher'

  isLoading = false
  isCEPinvalid = false

  seriesEscolares = this.language.serieEscolar
  serieEscolarNome = ''
  generos = this.language.genero
  generoNome = ''
  estados = this.language.estados
  estadoNome = ''

  isLoadingPage = false

  addressOrder = {
    cep: 0,
    endereco: 0,
    splitField: {
      order: 0,
      numero: 0,
      complemento: 0,
    },
    bairro: 0,
    estado: 0,
    cidade: 0
  }

  displayForm = {
    cep: true,
    endereco: true,
    numero: true,
    complemento: true,
    bairro: true,
    estado: true,
    cidade: true
  }

  allCountries: CountryList[] = this.countryService.allCountries
  cellphoneFormat = ''
  cellphonePlaceholder = ''

  steps = [
    {
      'nome': 'step-1',
      'ativo': true
    },
    {
      'nome': 'step-2',
      'ativo': false
    },
    {
      'nome': 'step-3',
      'ativo': false
    }
  ]

  dadosAlunoConsent = {
    nome: '',
    nascimento: '',
    code: ''
  }

  aluno: ChildData = {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    schoolGrade: '',
    schoolName: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phone: '',
    email: ''
  }

  @ViewChild('stickyMenu') menuElement!: ElementRef
  sticky: boolean = false;
  elementPosition: any


  formDadosAluno = new FormGroup({
    nome: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/[a-zA-ZÀ-ž]+\s+[a-zA-ZÀ-ž]+/)]),
    genero: new FormControl('', [Validators.required]),
    nascimento: new FormControl('', [Validators.required, futureDate, pastDate, minDate, invalidDate, Validators.minLength(10)]),
    serie: new FormControl('', [Validators.required]),
    escola: new FormControl(''),
    telefone: new FormControl(),
    email: new FormControl('', Validators.email),
    code: new FormControl()
  })

  formEnderecoAluno = new FormGroup({
    cep: new FormControl('', [Validators.required, Validators.minLength(8)]),
    endereco: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)]),
    numero: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-Z0-9]+/)]),
    complemento: new FormControl(''),
    bairro: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)]),
    estado: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required, Validators.pattern(/[a-zA-ZÀ-ž0-9]+/)])
  })

  constructor(
    private router: Router,
    private el: ElementRef,
    private http: HttpClient,
    private apiService: ApisService,
    private confirm: ConfirmService,
    private dados: DadosService,
    private language: LanguageService,
    private countryService: CountryListService
  ) { }

  @HostListener('window:scroll', ['$event'])
  handleScroll(e: any) {
    const windowScroll = window.scrollY

    if (windowScroll >= this.elementPosition) {
      this.sticky = true
    } else {
      this.sticky = false
    }
  }

  @HostListener('window:resize', ['$event'])
  handleResize(e: any) {
    this.elementPosition = this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY

  }

  ngAfterViewInit() {
    this.elementPosition =
      this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY

    setTimeout(() => {
      this.elementPosition =
        this.menuElement.nativeElement.parentElement.getBoundingClientRect().y + window.scrollY
    })

  }

  ngOnInit(): void {
    this.handleInitialData()
    this.changeFormByCountry()
    this.changePhoneFormat(this.dados.getDados().selectedPhoneChild?.isoCode || this.country)
  }

  async handleInitialData() {

    if (sessionStorage.getItem('dadosAluno')) {

      this.populaCamposVoltarEtapa()

    } else if (sessionStorage.getItem('ksis')) {

      const { dados, endereco } = await this.getDadosKsis()

      this.formDadosAluno.patchValue(dados)
      this.formEnderecoAluno.patchValue(endereco)

      console.log(dados, endereco)

    } else {

      this.populaCamposConsent()
    }
  }

  getDadosKsis() {
    return new Promise<any>(resolve => {

      const { student } = JSON.parse(sessionStorage.getItem('ksis')!)

      const filtroEstado = this.estados.filter((item: any) => student.stateCode === item.sigla)

      const filtroSerie = this.seriesEscolares.filter((item: any) => (student.gradeLevel && student.gradeLevel.trim()) === item.valor)
      
      const filtroGenero = this.generos.filter((item: any) => student.gender.toLowerCase() === item.valor)

      const aluno = {
        dados: {
          nome: this.apiService.removeSpaces(this.dados.getDados().studentName),
          genero: filtroGenero.length > 0 ? filtroGenero[0].valor : '',
          nascimento: moment(this.dados.getDados().dateOfBirth.split('T')[0], 'YYYY-MM-DD').format("DD/MM/YYYY"),
          serie: filtroSerie.length > 0 ? filtroSerie[0].valor : '',
          escola: student.schoolName,
          code: this.dados.getDados().selectedPhoneChild?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode,
          telefone: student.phoneNumber,
          email: this.apiService.removeSpaces(student.email),
        },
        endereco: {
          cep: this.apiService.removeSpaces(student.zipCode || ''),
          endereco: this.apiService.removeSpaces(student.address1),
          numero: this.apiService.removeSpaces(student.address2),
          complemento: this.apiService.removeSpaces(student.address3),
          bairro: this.apiService.removeSpaces(student.address4),
          estado: filtroEstado.length > 0 ? filtroEstado[0].sigla : '',
          cidade: this.apiService.removeSpaces(student.city || '')
        }
      }

      this.filterOptions(aluno.endereco, aluno.dados)

      resolve(aluno)
    })
  }

  getDadosApiConsentOnInit() {
    if (!this.dados.hasDados()) return

    return new Promise<void>(resolve => {
      this.dadosAlunoConsent = {
        nome: this.apiService.removeSpaces(this.dados.getDados().studentName),
        nascimento: moment(this.dados.getDados().dateOfBirth.split('T')[0], 'YYYY-MM-DD').format("DD/MM/YYYY"),
        code: this.dados.getDados().selectedPhoneChild?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode
      }
      resolve()
    })

  }

  async populaCamposConsent() {

    await this.getDadosApiConsentOnInit()

    this.formDadosAluno.patchValue(this.dadosAlunoConsent)

    this.formDadosAluno.controls['nome'].disable()
    this.formDadosAluno.controls['nascimento'].disable()

  }

  mudaEtapa(id: number) {
    this.steps.forEach((el) => {
      el.ativo = false
    })

    this.steps[id].ativo = true

    window.scroll(0, 330)
  }

  proximaPagina() {
    if (this.validarParte()) {
      if (this.stepAtivo < this.steps.length - 1) {
        this.stepAtivo++

        this.mudaEtapa(this.stepAtivo)
      } else {

        this.transformAndSaveValues()

        let name = this.apiService.splitName(this.formDadosAluno.getRawValue().nome)

        this.aluno = {
          firstName: name.firstName,
          middleName: name.middleName,
          lastName: name.lastName,
          dateOfBirth: this.formDadosAluno.getRawValue().nascimento,
          gender: this.formDadosAluno.value.genero,
          schoolGrade: this.formDadosAluno.value.serie,
          schoolName: this.formDadosAluno.value.escola,
          address1: this.formEnderecoAluno.value.endereco,
          address2: this.formEnderecoAluno.value.numero,
          address3: this.formEnderecoAluno.value.complemento,
          address4: this.formEnderecoAluno.value.bairro,
          city: this.formEnderecoAluno.value.cidade,
          state: this.formEnderecoAluno.getRawValue().estado,
          postalCode: this.formEnderecoAluno.value.cep,
          country: this.dados.getDados().countryId,
          phone: this.formDadosAluno.value.telefone,
          email: this.formDadosAluno.value.email
        }

        this.confirm.setConfirm({ childData: this.aluno })

        console.log(this.aluno)

        this.router.navigate(['/cadastro-responsavel'])
      }
    }
  }

  transformAndSaveValues() {

    this.formDadosAluno.patchValue({
      nome: this.apiService.removeSpaces(this.formDadosAluno.getRawValue().nome, true),
      nascimento: moment(this.formDadosAluno.getRawValue().nascimento, 'DD/MM/YYYY').format("YYYY-MM-DD"),
      escola: this.apiService.removeSpaces(this.formDadosAluno.value.escola),
      telefone: this.formDadosAluno.value.code + this.formDadosAluno.value.telefone,
      email: this.apiService.removeSpaces(this.formDadosAluno.value.email)
    })

    this.formEnderecoAluno.patchValue({
      endereco: this.apiService.removeSpaces(this.formEnderecoAluno.value.endereco),
      numero: this.apiService.removeSpaces(this.formEnderecoAluno.value.numero),
      complemento: this.apiService.removeSpaces(this.formEnderecoAluno.value.complemento, false),
      bairro: this.apiService.removeSpaces(this.formEnderecoAluno.value.bairro),
      cidade: this.apiService.removeSpaces(this.formEnderecoAluno.value.cidade)
    })

    let dadosAluno = [
      this.formDadosAluno.getRawValue(),
      this.formEnderecoAluno.getRawValue()
    ]

    sessionStorage.setItem("dadosAluno", JSON.stringify(dadosAluno))
  }

  validarParte() {

    if (this.stepAtivo == 0) {
      if (!this.formDadosAluno.valid) {
        this.setFocus(this.formDadosAluno.controls)
        this.formDadosAluno.markAllAsTouched()
        return false
      }
    } else {
      if (!this.formEnderecoAluno.valid) {
        this.setFocus(this.formEnderecoAluno.controls)
        this.formEnderecoAluno.markAllAsTouched()
        return false
      }
    }
    return true
  }

  setFocus(formControl: any) {
    for (const key of Object.keys(formControl)) {
      if (formControl[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formControlName="' + key + '"]')
        invalidControl.focus()
        break
      }
    }
  }

  voltaPagina() {
    if (this.stepAtivo != 0) {
      this.stepAtivo--
      this.mudaEtapa(this.stepAtivo)
    } else {
      this.router.navigate([''])
    }
  }

  populaCamposVoltarEtapa() {

    const dadosAluno = sessionStorage.getItem('dadosAluno')

    let { length } = this.dados.getDados().selectedPhoneChild?.dialCode ?? this.countryService.getInfoCountry(this.country).dialCode

    if (!dadosAluno) return

    const sessionData = JSON.parse(dadosAluno)
    const formDados = Object.keys(this.formDadosAluno.value)
    const formEndereco = Object.keys(this.formEnderecoAluno.value)

    formDados.forEach(el => {

      if (el in sessionData[0]) {
        return this.formDadosAluno.patchValue(
          {
            ...sessionData[0],
            nascimento: moment(sessionData[0].nascimento, 'YYYY-MM-DD').format("DD/MM/YYYY"),
            telefone: sessionData[0].telefone.slice(length),
          }
        )
      }
    })

    formEndereco.forEach(el => {

      if (el in sessionData[1]) {
        return this.formEnderecoAluno.patchValue(
          {
            ...sessionData[1]
          }
        )
      }
    })

    this.filterOptions(this.formEnderecoAluno.value, this.formDadosAluno.value)

  }

  filterOptions(addressValue: any, dataValue: any) {

    const filtroEstado = this.estados.filter((item: any) => addressValue.estado === item.sigla)
    const filtroGenero = this.generos.filter((item: any) => dataValue.genero === item.valor)
    const filtroSerie = this.seriesEscolares.filter((item: any) => dataValue.serie === item.valor)

    const estado = filtroEstado.length > 0 ? filtroEstado[0].nome : ''
    const genero = filtroGenero.length > 0 ? filtroGenero[0].nome : ''
    const serie = filtroSerie.length > 0 ? filtroSerie[0].nome : ''

    if (!this.formEnderecoAluno.controls.cep.disabled) this.formataEstado(estado)

    this.formataGenero(genero)
    this.formataSerieEscolar(serie)
  }

  onSelectCountryCode(isoCode: any) {

    let selectedPhoneChild = {
      isoCode: this.countryService.getInfoCountry(isoCode).isoCode,
      dialCode: this.countryService.getInfoCountry(isoCode).dialCode,
    }

    this.formDadosAluno.patchValue({
      telefone: ''
    })

    this.dados.setDados({ selectedPhoneChild })

    this.changePhoneFormat(isoCode)
  }


  changePhoneFormat(country: string) {

    let selected = this.countryService.getInfoCountry(country)

    if (country === 'BR') {
      this.cellphoneFormat = '(00) 00000-0000||(00) 0000-0000'
      this.formDadosAluno.get('telefone')?.setValidators([Validators.required, Validators.minLength(selected.homePhone.minLength)])
    } else {
      this.cellphoneFormat = selected.cellphone.format
      this.formDadosAluno.get('telefone')?.setValidators([Validators.required, Validators.minLength(selected.cellphone.minLength)])
    }

    this.cellphonePlaceholder = selected.cellphone.placehoder
    this.formDadosAluno.get('telefone')?.updateValueAndValidity()

  }

  changeFormByCountry() {

    switch (this.country) {

      case 'CL':
        this.displayForm = {
          ...this.displayForm,
          cep: false,
          estado: false
        }

        this.formEnderecoAluno.controls['cep'].disable()
        this.formEnderecoAluno.controls['estado'].disable()

        this.formEnderecoAluno.patchValue({
          estado: 'CL'
        })

        break

      case 'CO':

        this.displayForm = {
          ...this.displayForm,
          numero: false,
          cep: false,
          estado: false
        }

        this.addressOrder = {
          ...this.addressOrder,
          cidade: 1,
          bairro: 2,
          endereco: 3,
          splitField: {
            order: 4,
            numero: 5,
            complemento: 6,
          },
        }


        this.formEnderecoAluno.controls['cep'].disable()
        this.formEnderecoAluno.controls['numero'].disable()
        this.formEnderecoAluno.controls['estado'].disable()

        this.formEnderecoAluno.patchValue({
          estado: 'CO'
        })

        break

      case 'AR':

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          estado: false
        }

        this.formEnderecoAluno.controls['cep'].disable()
        this.formEnderecoAluno.controls['estado'].disable()

        this.formEnderecoAluno.patchValue({
          estado: 'AR'
        })

        break

      case 'BO':

        this.displayForm = {
          ...this.displayForm,
          cep: false,
        }

        this.formEnderecoAluno.controls['cep'].disable()

        break

      case 'PE':

        this.displayForm = {
          ...this.displayForm,
          cep: false,
        }

        this.formEnderecoAluno.controls['cep'].disable()

        break

      case 'UY':

        this.displayForm = {
          ...this.displayForm,
          cep: false,
          bairro: false
        }

        this.formEnderecoAluno.controls['cep'].disable()
        this.formEnderecoAluno.controls['bairro'].disable()

        break
    }
  }

  formataSerieEscolar(option: any) {
    this.serieEscolarNome = option
  }

  formataGenero(option: any) {
    this.generoNome = option
  }

  formataEstado(option: any) {
    this.estadoNome = option
  }

  openModalAnoEscolar(ev: Event) {
    this.modal = new bootstrap.Modal(document.getElementById(this.idModal)!)
    this.modal.show()
  }

  consultaCEP(e: any) {

    let cep = this.formEnderecoAluno.get('cep')?.value

    // this.isCEPinvalid = false

    if (cep != '' && cep.length === 8) {

      this.isLoading = true

      this.http.get(`//viacep.com.br/ws/${cep}/json`, { headers: { skip: "true" } })
        .subscribe(
          (dados: any) => {

            this.isLoading = false

            if ('erro' in dados) {
              // this.isCEPinvalid = true
              // this.formEnderecoAluno.patchValue({
              //   endereco: '',
              //   bairro: '',
              //   estado: '',
              //   cidade: ''
              // })

            } else {
              this.populaCEP(dados)

              let filtro = this.estados.filter(el => {
                return el.sigla === dados.uf
              })

              this.formataEstado(filtro[0].nome)
            }
          },
          err => {
            this.isLoading = false
          }
        )

    }
  }

  populaCEP(dados: any) {
    this.formEnderecoAluno.patchValue({
      endereco: this.abreviaLogradouro(dados.logradouro).substring(0, 50),
      bairro: dados.bairro.substring(0, 40),
      estado: dados.uf,
      cidade: dados.localidade
    })
  }

  alphaOnly(e: any) {
    let regex = new RegExp("^[a-zA-ZÀ-ž' ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

  abreviaLogradouro(texto: any) {

    let obj: any = {
      Avenida: "Av.",
      Professor: "Prof.",
      Professora: "Profa.",
      Doutor: "Dr.",
      Doutora: "Dra."
    }

    Object.keys(obj).forEach((key) => {
      texto = texto.replaceAll(key, obj[key])
    })
    return texto
  }

}
