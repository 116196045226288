import { Component, isDevMode, OnInit } from '@angular/core'
import { Router } from '@angular/router'

declare const gtag: Function

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(public router: Router) {

  }

  ngOnInit() {
    if (!isDevMode()) {
      console.log = function () { }
    }
  }
}
