import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { concatMap, map, tap } from 'rxjs/operators'
import { Buttons, Home } from 'src/app/models/language.model'
import { ApisService } from 'src/app/services/apis.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { TokenService } from 'src/app/services/token.service'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  getData$!: Subscription

  lang!: Home
  langButton!: Buttons

  textLoading = this.language.home.textLoadingValidate
  titleMessage = ''
  textMessage = ''

  isLoading: boolean = true
  isAuthenticated: boolean = true
  displayEmail: string = ''
  emailValido = true
  msgError: string = ''
  botaoAtivo = false
  botaoCarregando = false
  iconeBotaoEmail = 'fas fa-arrow-alt-circle-right'

  // financeResponsibleSource!: DataSource

  user = {
    hash: '',
    email: ''
  }

  emailUsuario = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private apiService: ApisService,
    private authService: AuthService,
    private router: Router,
    private dados: DadosService,
    private route: ActivatedRoute,
    private language: LanguageService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.getData$ = this.authService.auth()
      .pipe(

        map((data: any) => {
          this.tokenService.setToken(data.token)
          return data.token
        }),

        concatMap(() => this.newEnrollment()),

        concatMap((res) => this.getKsisData(res.countryId)),

        concatMap(() => this.authService.authConsent()),

        map((data: any) => {
          sessionStorage.setItem('consentToken', data.token)
          return data.token
        }),

        concatMap(() => this.byPass())
      )
      .subscribe({
        next: res => { },
        error: err => {
          console.log('🔴 Error', err)

          this.titleMessage = this.language.errorMessage.titleMessageError
          this.textMessage = this.language.errorMessage.textMessageError
          this.isAuthenticated = false
          this.isLoading = false
        }
      })
  }

  newEnrollment() {
    return this.apiService.newEnrollment().pipe(
      map(res => {
        this.textLoading = this.language.home.textLoadingUpdate

        if (res.email) {
          this.user.hash = this.apiService.hash
          this.displayEmail = res.email

          this.dados.setDados({
            ...res,
            hash: this.apiService.hash
          })
        }

        if (res.countryId) {
          this.lang = this.language.home
          this.langButton = this.language.buttons
        }

        return res

      })
    )
  }

  getKsisData(country: string) {
    return this.apiService.getKsisData(country).pipe(
      map(res => {

        if (res) sessionStorage.setItem("ksis", JSON.stringify(res))

        return res
      })
    )

  }

  byPass() {
    return this.route.queryParams.pipe(
      map(param => {

        this.user.hash = this.apiService.hash

        if (param.email) {
          this.user.email = param.email
          this.dados.setDados({ emailResponsavel: param.email })
          this.confirmEmail(this.user)
        } else {
          this.isLoading = false
        }
      })
    )
  }

  confirmEmail(user: any) {
    this.apiService.email(user).subscribe(
      res => {
        if (this.dados.getDados().emailResponsavel === undefined) {
          this.dados.setDados({ emailResponsavel: this.emailUsuario.value })
        }
        this.router.navigate(['cadastro-aluno'])

        console.log(res)
      },
      err => {
        this.botaoCarregando = false
        this.iconeBotaoEmail = 'fas fa-arrow-alt-circle-right'
        this.msgError = this.language.apiError(err.error)
        this.emailValido = false
      }
    )
  }

  proximaPagina() {
    this.user.email = this.emailUsuario.value
    this.iconeBotaoEmail = 'fas fa-spinner'
    this.botaoCarregando = true

    this.confirmEmail(this.user)
  }

  limpaErro() {
    this.emailValido = true
  }

  ngOnDestroy(): void {
    this.getData$.unsubscribe()
  }

}
