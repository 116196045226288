import { Injectable } from '@angular/core';

const KEY =  'confirm'

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor() { }

  hasConfirm(){
    return !!this.getConfirm();
  }

  setConfirm(obj: any){

    if (!this.hasConfirm()) {
      sessionStorage.setItem(KEY, JSON.stringify(obj))
    } else {
      sessionStorage.setItem(KEY, JSON.stringify(Object.assign(this.getConfirm(), obj)))
    }

  }

  getConfirm(){
    const data = sessionStorage.getItem(KEY)
    return JSON.parse(data!)
  }

  removeAnswers(){
    sessionStorage.removeItem(KEY)
  }
}
