import { Component, OnInit } from '@angular/core';
import { Buttons } from 'src/app/models/language.model';
import { DadosService } from 'src/app/services/dados.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {

  langButtons: Buttons = this.language.buttons
  country = this.dados.getDados().countryId

  linkPDF = {
    br: "https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Fksastinstrsite.blob.core.windows.net%2Fmol%2Femail-epreenrollment%2FPreenchendo%2520a%2520FMT%2520Online.pdf&data=05%7C01%7Crenato.martins%40kumon.com.br%7Cef440c3e50194be7fe0008da9dc72d87%7C65b7f2433785438ba6f5ebecebfae111%7C0%7C0%7C637995776040963802%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2BU%2F2iuCIvMRk36kXIRSKuv4gP7cL%2FUHWNl1Q5XOmvqM%3D&reserved=0",
    hh: 'https://ksastinstrsite.blob.core.windows.net/teams/Completando%20la%20Ficha%20de%20Matr%C3%ADcula%20Online.pdf'
  }

  constructor(
    private language: LanguageService,
    private dados: DadosService
  ) { }

  ngOnInit(): void {
    console.log('help button', this.country)
  }

}
