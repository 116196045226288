import { Injectable } from '@angular/core'
import { Address, Buttons, CadastroAluno, CadastroResponsavel, Calendar, DemaisCondicoes, Disciplinas, ErrorInput, ErrorMessage, Final, Home, Prefix, SelectCountryPhone, Steps } from './../models/language.model'
import { DadosService } from './dados.service'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentCountry = ''
  urlCountry = window.location.hostname.split(".").pop()!.substring(0, 2).toUpperCase()

  constructor(private dadosService: DadosService) { }

  get country() {
    return this.dadosService.hasDados()
      ? this.currentCountry = this.dadosService.getDados().countryId.toUpperCase()
      : this.currentCountry = this.urlCountry
  }

  selectLanguage(arr: any[]) {

    let countrySelected
    const spanish = 'HH'

    const countryFilter = arr.find(item => item.country === this.country)
    const noCountryFound = arr.find(item => item.country === spanish)

    return countryFilter
      ? countrySelected = countryFilter
      : countrySelected = noCountryFound

  }

  get steps() {

    let content: Steps[] = [
      {
        country: 'BR',
        one: 'Política de privacidade',
        two: 'Dados cadastrais',
        three: 'Escolha da Disciplina',
        four: 'Condições do curso',
        five: 'Finalização'
      },
      {
        country: 'HH',
        one: 'Política de Privacidad',
        two: 'Datos de Registro',
        three: 'Selección de asignatura',
        four: 'Condiciones del curso',
        five: 'Finalización'
      }
    ]

    return this.selectLanguage(content)
  }


  get home() {

    let content: Home[] = [
      {
        country: 'BR',
        email: 'Você está iniciando o processo de matrícula para o e-mail:',
        confirmEmail: 'Confirme o e-mail no campo abaixo:',
        placeholder: 'Insira seu e-mail',
        textLoadingValidate: 'Validando os dados',
        textLoadingUpdate: 'Atualizando informações'
      },
      {
        country: 'HH',
        email: "Usted está iniciando el proceso de matrícula para el e-mail:",
        confirmEmail: "Confirme su e-mail en el campo abajo:",
        placeholder: "Ingrese tu e-mail",
        textLoadingValidate: "Validando los datos",
        textLoadingUpdate: "Actualizando informaciones"
      }
    ]

    return this.selectLanguage(content)
  }


  get cadastroAluno() {
    let content: CadastroAluno[] = [
      {
        country: 'BR',
        title: 'Matrícula',
        caption: 'Preencha os dados do',
        formType: 'aluno',
        stepOne: 'Dados pessoais do aluno',
        stepTwo: 'Endereço do aluno',
        stepThree: 'Validação',
        captionStepThree: 'Verifique se os dados do aluno estão corretos:',
        labelFullName: 'Nome completo',
        placeholderFullName: 'Inserir o nome',
        labelGender: 'Sexo',
        placeholderGender: 'Selecione',
        labelDateOfBirth: 'Data de nascimento',
        labelSchoolGrade: 'Ano escolar',
        placeholderSchoolGrade: 'Selecione o ano escolar',
        labelSchoolName: 'Escola em que estuda',
        placeholderSchoolName: 'Inserir a escola',
        labelPhone: 'Telefone',
        labelEmail: 'E-mail',
        placeholderEmail: 'Inserir o e-mail do aluno',
        modalTitle: 'Como preencher?',
        modalText: 'Como referência, a idade mínima para ingresso no 1° do Ensino Fundamental é 6 anos completos em março.'
      },
      {
        country: 'HH',
        title: 'Matrícula',
        caption: 'Ingrese los datos del',
        formType: 'alumno:',
        stepOne: 'Datos personales del alumno',
        stepTwo: 'Dirección del alumno',
        stepThree: 'Validación',
        captionStepThree: 'Confirme si los datos del alumno están correctos:',
        labelFullName: 'Nombre completo',
        placeholderFullName: 'Digita el nombre',
        labelGender: 'Sexo',
        placeholderGender: 'Seleccione',
        labelDateOfBirth: 'Fecha de nacimiento',
        labelSchoolGrade: 'Grado escolar',
        placeholderSchoolGrade: 'Seleccione el grado escolar',
        labelSchoolName: 'Escuela del alumno',
        placeholderSchoolName: 'Ingrese la escuela',
        labelPhone: 'Teléfono',
        labelEmail: 'E-mail',
        placeholderEmail: 'Ingrese el e-mail del alumno',
        modalTitle: '¿Cómo completar?',
        modalText: 'Como referencia, la edad minima para ingresar a 1° de Primaria es de 6 años en el mes de marzo.'
      }
    ]

    return this.selectLanguage(content)
  }


  get cadastroResponsavel() {

    let content: CadastroResponsavel[] = [
      {
        country: 'BR',
        title: 'Matrícula',
        caption: 'Preencha os dados do',
        formType: 'responsável',
        formTypeFinance: 'responsável financeiro',
        stepFour: 'Dados pessoais do responsável',
        stepFive: 'Endereço do responsável',
        stepSix: 'Validação',
        captionStepSix: 'Verifique se os dados do responsável estão corretos:',
        labelFullName: 'Nome completo do responsável',
        placeholderFullName: 'Inserir o nome',
        labelPersonalDocument: 'CPF',
        placeholderPersonalDocument: 'Inserir o CPF',
        labelRelationship: 'Relação com o aluno',
        placeholderRelationship: 'Selecione',
        labelHomePhone: 'Telefone residencial',
        labelWorkPhone: 'Telefone comercial',
        labelCellphone: 'Telefone celular',
        labelEmail: 'E-mail',
        placeholderEmail: 'Inserir o e-mail',
        labelSameAdress: 'Mesmo endereço do aluno',
        sameGuardianValue: 'Utilizar os mesmos dados do RESPONSÁVEL'
      },
      {
        country: 'HH',
        title: 'Matrícula',
        caption: 'Ingrese los datos del',
        formType: 'apoderado',
        formTypeFinance: 'responsável financiero',
        stepFour: 'Datos personales del apoderado',
        stepFive: 'Dirección del apoderado',
        stepSix: 'Validación',
        captionStepSix: 'Confirme si los datos del apoderado están correctos:',
        labelFullName: 'Nombre completo del apoderado',
        placeholderFullName: 'Ingrese el nombre',
        labelPersonalDocument: 'Documento personal',
        placeholderPersonalDocument: 'Ingrese el documento personal',
        labelRelationship: 'Relación con el alumno',
        placeholderRelationship: 'Seleccione',
        labelHomePhone: 'Teléfono residencial',
        labelWorkPhone: 'Teléfono comercial',
        labelCellphone: 'Teléfono celular',
        labelEmail: 'E-mail',
        placeholderEmail: 'Ingrese el e-mail',
        labelSameAdress: 'Misma dirección del alumno',
        sameGuardianValue: 'Utilizar los mismos datos que el APODERADO'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'CL',
        labelPersonalDocument: 'RUT',
        placeholderPersonalDocument: 'Ingrese el RUT',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'CO',
        labelPersonalDocument: 'Cédula para facturar',
        placeholderPersonalDocument: 'Ingrese la cédula para facturar',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'AR',
        labelPersonalDocument: 'DNI',
        placeholderPersonalDocument: 'Ingrese el DNI',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'BO',
        labelPersonalDocument: 'CI o NIT',
        placeholderPersonalDocument: 'Ingrese el CI o NIT',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'PE',
        labelPersonalDocument: 'DNI',
        placeholderPersonalDocument: 'Ingrese el DNI',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'UY',
        labelPersonalDocument: 'Nº de identidad',
        placeholderPersonalDocument: 'Ingrese el Nº de identidad',
      }
    ]

    return this.selectLanguage(content)
  }

  get endereco() {
    let content: Address[] = [
      {
        country: 'BR',
        labelPostalCode: 'CEP',
        placeholderPostalCode: 'Inserir o CEP',
        labelAddress1: 'Endereço',
        placeholderAddress1: 'Inserir o endereço',
        labelAddress2: 'Número',
        placeholderAddress2: 'Inserir o número do domicílio',
        labelAddress3: 'Complemento',
        placeholderAddress3: 'Inserir complemento (opcional)',
        labelAddress4: 'Bairro',
        placeholderAddress4: 'Inserir o bairro',
        labelState: 'Estado',
        placeholderState: 'Selecione o estado',
        labelCity: 'Cidade',
        placeholderCity: 'Inserir a cidade',
      },
      {
        country: 'HH',
        labelPostalCode: 'Código postal',
        placeholderPostalCode: 'Ingrese el código postal',
        labelAddress1: 'Dirección',
        placeholderAddress1: 'Ingrese la dirección',
        labelAddress2: 'Número',
        placeholderAddress2: 'Ingrese el número de la casa',
        labelAddress3: 'Complemento',
        placeholderAddress3: 'Ingrese complemento (opcional)',
        labelAddress4: 'Barrio',
        placeholderAddress4: 'ingrese el barrio',
        labelState: 'Estado',
        placeholderState: 'Ingrese estado',
        labelCity: 'Ciudad',
        placeholderCity: 'Ingrese la ciudad',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'CL',
        labelAddress4: 'Comuna',
        placeholderAddress4: 'Ingrese la comuna',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'AR',
        labelAddress1: 'Calle',
        placeholderAddress1: 'Ingrese la calle',
        labelAddress4: 'Barrio/Localidad',
        placeholderAddress4: 'Ingrese el barrio/localidad',
        labelCity: 'Provincia',
        placeholderCity: 'Ingrese la provincia',
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'BO',
        labelAddress4: 'Distrito',
        placeholderAddress4: 'Ingrese el distrito',
        labelState: 'Departamento',
        placeholderState: 'Ingrese el departamento'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'PE',
        labelAddress4: 'Distrito',
        placeholderAddress4: 'Ingrese el distrito',
        labelState: 'Departamento',
        placeholderState: 'Ingrese el departamento'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'UY',
        labelState: 'Departamento',
        placeholderState: 'Ingrese el departamento'
      }
    ]

    return this.selectLanguage(content)
  }

  get disciplinas() {

    let content: Disciplinas[] = [
      {
        country: 'BR',
        title: 'Matrícula',
        labelClassSubjects: 'Selecione abaixo as',
        HighlightLabelClassSubjects: 'disciplinas que irá se matricular:',
        labelTransfer: 'Transferência de unidade',
        inputTransfer: 'Já sou aluno Kumon em outra unidade',
        labelHopeDate: 'Data prevista da primeira aula',
        placeholderHopeDate: 'Selecione uma data'
      },
      {
        country: 'HH',
        title: 'Matrícula',
        labelClassSubjects: 'Seleccione abajo las',
        HighlightLabelClassSubjects: 'asignaturas que vas a matricular:',
        labelTransfer: 'Transferencia de unidades',
        inputTransfer: 'Ya soy alumno de Kumon en otra unidad',
        labelHopeDate: 'Fecha prevista de la primera clase',
        placeholderHopeDate: 'Selecciona una fecha'
      }
    ]

    return this.selectLanguage(content)
  }


  get demaisCondicoes() {

    let content: DemaisCondicoes[] = [
      {
        country: 'BR',
        title: 'Matrícula'
      },
      {
        country: 'HH',
        title: 'Matrícula'
      }
    ]

    return this.selectLanguage(content)
  }


  get final() {
    let content: Final[] = [
      {
        country: 'BR',
        title: 'Matrícula',
        greeting: 'Parabéns!',
        textEnrollmentComplete1: 'A matrícula do aluno(a):',
        textEnrollmentComplete2: 'foi concluída com sucesso.',
        textEmailConfirmation: 'Você receberá por e-mail uma confirmação do processo de matrícula.',
        textGeneratedCode: 'Segue abaixo o código de matrícula gerado:',
        textNotification: 'A unidade do Kumon que você fez a matrícula já foi notificada que o seu processo foi concluído.',
        textNew: 'Caso deseje matricular mais um aluno neste mesmo processo, por favor',
        linkClickHere: 'clique aqui',
        textOtherwiseCloseWindow: 'Caso contrário, você pode fechar esta janela.',
        textCloseWindow: 'Você pode fechar esta janela.'
      },
      {
        country: 'HH',
        title: 'Matrícula',
        greeting: '¡Felicitaciones!',
        textEnrollmentComplete1: 'La matrícula del alumno(a):',
        textEnrollmentComplete2: 'se completó con éxito.',
        textEmailConfirmation: 'Usted recibirá un e-mail de confirmación del proceso de matrícula.',
        textGeneratedCode: 'Sigue el código de registro generado:',
        textNotification: 'La unidad Kumon que se registró ya ha sido notificada de que su proceso está completo.',
        textNew: 'Si desea matricular a otro alumno en este mismo proceso, por favor',
        linkClickHere: 'haga clic aquí',
        textOtherwiseCloseWindow: 'De lo contrario, puede cerrar esta ventana.',
        textCloseWindow: 'Puedes cerrar esta ventana.'
      }
    ]

    return this.selectLanguage(content)
  }


  get errorMessage() {

    let content: ErrorMessage[] = [
      {
        country: 'BR',
        titleMessageEnrollmentComplete: 'Matrícula já preenchida',
        titleMessageError: 'Encontramos um problema',
        textMessageEnrollmentComplete: `
          <p>
            Parece que você já concluiu o processo de matrícula online.
          </p>
          <p>
            Caso tenha algum problema, entre em contato com o seu orientador para realizar a confirmação.
          </p>
        `,
        textMessageError: `
          <p>
            O link de matrícula que você está utilizando está fora do prazo máximo
            permitido ou está indisponível.
          </p>
          <p>
            Por favor, entre em contato com a unidade e solicite um novo link para
            efetuar a matrícula.
          </p>
        `
      },
      {
        country: 'HH',
        titleMessageEnrollmentComplete: 'Matrícula ya llena',
        titleMessageError: 'Encontramos un problema',
        textMessageEnrollmentComplete: `
          <p>
            Parece que usted ya completó el proceso de matrícula.
          </p>
          <p>
          Si tiene algún problema, comuníquese con su orientador para confirmación.
          </p>
        `,
        textMessageError: `
          <p>
            El link de matrícula que está utilizando no está actualizado o no está disponible.
          </p>
          <p>
            Comuníquese con la unidad y solicite un nuevo link para registrarse.
          </p>
        `
      }
    ]

    return this.selectLanguage(content)
  }


  get errorInput() {

    let content: ErrorInput[] = [
      {
        country: 'BR',
        required: 'Campo obrigatório.',
        incompleteFullName: 'Por favor, preencha o nome completo.',
        futureDate: 'O valor informado não pode ser maior do que a data atual.',
        pastDate: 'A data não pode ser anterior à cem anos.',
        minDate: 'A idade mínima é de um ano.',
        invalidDate: 'Data inválida.',
        incompleteDate: 'Data incompleta.',
        incompletePhone: 'Telefone incompleto.',
        invalidEmail: 'E-mail inválido.',
        postalCodeNotFound: 'CEP não encontrado.',
        incompletePostalCode: 'CEP incompleto.',
        incompletePersonalDocument: 'CPF incompleto.',
        invalidPersonalDocument: 'CPF Inválido.'
      },
      {
        country: 'HH',
        required: 'Campo obligatorio.',
        incompleteFullName: 'Por favor, complete el nombre completo.',
        futureDate: 'La fecha no puede ser mayor que la fecha actual.',
        pastDate: 'La fecha no puede ser anterior a 100 años.',
        minDate: 'La edad mínima es de un año.',
        invalidDate: 'Fecha invalida.',
        incompleteDate: 'Fecha incompleta.',
        incompletePhone: 'Teléfono incompleto.',
        invalidEmail: 'e-mail inválido.',
        postalCodeNotFound: 'Código postal no encontrado.',
        incompletePostalCode: 'Código postal incompleto.',
        incompletePersonalDocument: 'Documento personal incompleto.',
        invalidPersonalDocument: 'Documento personal inválido.'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'CL',
        incompletePersonalDocument: 'RUT incompleto.',
        invalidPersonalDocument: 'RUT inválido.'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'CO',
        incompletePersonalDocument: 'Cédula para facturar incompleta.',
        invalidPersonalDocument: 'Cédula para facturar inválida.'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'AR',
        incompletePersonalDocument: 'DNI incompleto.',
        invalidPersonalDocument: 'DNI inválido.'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'BO',
        incompletePersonalDocument: 'CI o NIT incompleto.',
        invalidPersonalDocument: 'CI o NIT inválido.'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'PE',
        incompletePersonalDocument: 'DNI incompleto.',
        invalidPersonalDocument: 'DNI inválido.'
      }
    ]

    content = [
      ...content,
      {
        ...content[1],
        country: 'UY',
        incompletePersonalDocument: 'Nº de identidad incompleto.',
        invalidPersonalDocument: 'Nº de identidad inválido.'
      }
    ]

    return this.selectLanguage(content)
  }


  get buttons() {

    let content: Buttons[] = [
      {
        country: 'BR',
        start: 'Iniciar',
        back: 'Voltar',
        continue: 'Continuar',
        tryAgain: 'Tentar novamente',
        close: 'Fechar',
        acceptAndComplete: 'Aceitar e concluir',
        help: 'Clique aqui para abrir o conteúdo de ajuda.',
        newChild: 'Matricular outro aluno'
      },
      {
        country: 'HH',
        start: 'Iniciar',
        back: 'Volver',
        continue: 'Continuar',
        tryAgain: 'Intentar nuevamente',
        close: 'Cerca',
        acceptAndComplete: 'Aceptar y concluir',
        help: 'Haga clic aquí para abrir el contenido de ayuda.',
        newChild: 'Matricular a otro alumno'
      }
    ]

    return this.selectLanguage(content)
  }


  get genero() {

    let content = [
      {
        country: 'BR',
        options: [
          { nome: 'Masculino', valor: 'm' },
          { nome: 'Feminino', valor: 'f' },
          { nome: 'Não quero informar', valor: '0' }
        ]
      },
      {
        country: 'HH',
        options: [
          { nome: 'Masculino', valor: 'm' },
          { nome: 'Femenino', valor: 'f' },
          { nome: 'No quiero informar', valor: '0' }
        ]
      }
    ]

    return this.selectLanguage(content).options
  }


  get relacaoAluno() {

    let content = [
      {
        country: 'BR',
        options: [
          {
            valor: 0,
            tipo: 'Responsável/Próprio aluno'
          },
          {
            valor: 1,
            tipo: 'Mãe',
          },
          {
            valor: 2,
            tipo: 'Pai'
          }]
      },
      {
        country: 'HH',
        options: [
          {
            valor: 0,
            tipo: 'Apoderado'
          },
          {
            valor: 1,
            tipo: 'Madre',
          },
          {
            valor: 2,
            tipo: 'Padre'
          }
        ]
      }
    ]

    return this.selectLanguage(content).options
  }


  get serieEscolar() {

    let content = [
      {
        country: 'BR',
        options: [
          { nome: 'Faltam 4 anos ou mais para 1º ano EF', valor: "PK3" },
          { nome: 'Faltam 3 anos para 1º ano EF', valor: "PK2" },
          { nome: 'Faltam 2 anos para 1º ano EF', valor: "PK1" },
          { nome: 'Falta 1 ano para 1º ano EF', valor: "K" },
          { nome: '1º ano - Ensino Fundamental', valor: "1" },
          { nome: '2º ano - Ensino Fundamental', valor: "2" },
          { nome: '3º ano - Ensino Fundamental', valor: "3" },
          { nome: '4º ano - Ensino Fundamental', valor: "4" },
          { nome: '5º ano - Ensino Fundamental', valor: "5" },
          { nome: '6º ano - Ensino Fundamental', valor: "6" },
          { nome: '7º ano - Ensino Fundamental', valor: "7" },
          { nome: '8º ano - Ensino Fundamental', valor: "8" },
          { nome: '9º ano - Ensino Fundamental', valor: "9" },
          { nome: '1º ano - Ensino Médio', valor: "10" },
          { nome: '2º ano - Ensino Médio', valor: "11" },
          { nome: '3º ano - Ensino Médio', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'N/A', valor: "14" }
        ]
      },
      {
        country: 'CL',
        options: [
          { nome: 'Medio menor', valor: "PK3" },
          { nome: 'Medio mayor', valor: "PK2" },
          { nome: 'Pre kinder', valor: "PK1" },
          { nome: 'kinder', valor: "K" },
          { nome: 'Primero básico', valor: "1" },
          { nome: 'Segundo básico', valor: "2" },
          { nome: 'Tercero básico', valor: "3" },
          { nome: 'Cuarto básico', valor: "4" },
          { nome: 'Quinto básico', valor: "5" },
          { nome: 'Sexto básico', valor: "6" },
          { nome: 'Séptimo básico', valor: "7" },
          { nome: 'Octavo básico', valor: "8" },
          { nome: 'Primero medio', valor: "9" },
          { nome: 'Segundo medio', valor: "10" },
          { nome: 'Tercero medio', valor: "11" },
          { nome: 'Cuarto medio', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'N/A', valor: "14" }
        ]
      },
      {
        country: 'CO',
        options: [
          { nome: 'Párvulos', valor: "PK3" },
          { nome: 'Prekinder', valor: "PK2" },
          { nome: 'kinder', valor: "PK1" },
          { nome: 'Transición', valor: "K" },
          { nome: '1', valor: "1" },
          { nome: '2', valor: "2" },
          { nome: '3', valor: "3" },
          { nome: '4', valor: "4" },
          { nome: '5', valor: "5" },
          { nome: '6', valor: "6" },
          { nome: '7', valor: "7" },
          { nome: '8', valor: "8" },
          { nome: '9', valor: "9" },
          { nome: '10', valor: "10" },
          { nome: '11', valor: "11" },
          { nome: 'Universitario', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'Alumnos con necesidades especiales', valor: "14" }
        ]
      },
      {
        country: 'AR',
        options: [
          { nome: 'Hasta 3 años', valor: "PK3" },
          { nome: '3 años', valor: "PK2" },
          { nome: '4 años', valor: "PK1" },
          { nome: '5 años', valor: "K" },
          { nome: '1° grado', valor: "1" },
          { nome: '2° grado', valor: "2" },
          { nome: '3° grado', valor: "3" },
          { nome: '4° grado', valor: "4" },
          { nome: '5° grado', valor: "5" },
          { nome: '6° grado', valor: "6" },
          { nome: '7° grado', valor: "7" },
          { nome: '8° grado', valor: "8" },
          { nome: '9° grado', valor: "9" },
          { nome: '10° grado', valor: "10" },
          { nome: '11° grado', valor: "11" },
          { nome: '12° grado', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'No aplica grado escolar', valor: "14" }
        ]
      },
      {
        country: 'BO',
        options: [
          { nome: 'Pre 4 o menos', valor: "PK3" },
          { nome: 'Pre 5', valor: "PK2" },
          { nome: 'Pre 6', valor: "PK1" },
          { nome: 'kinder', valor: "K" },
          { nome: '1º P (1º ano de primária)', valor: "1" },
          { nome: '2º P (2º ano de primária)', valor: "2" },
          { nome: '3º P (3º ano de primária)', valor: "3" },
          { nome: '4º P (4º ano de primária)', valor: "4" },
          { nome: '5º P (5º ano de primária)', valor: "5" },
          { nome: '6º P (6º ano de primária)', valor: "6" },
          { nome: '1º S (1º ano de secundária)', valor: "7" },
          { nome: '2º S (2º ano de secundária)', valor: "8" },
          { nome: '3º S (3º ano de secundária)', valor: "9" },
          { nome: '4º S (4º ano de secundária)', valor: "10" },
          { nome: '5º S (5º ano de secundária)', valor: "11" },
          { nome: '6º S (6º ano de secundária)', valor: "12" },
          { nome: 'Adultos', valor: "13" },
          { nome: 'EE', valor: "14" }
        ]
      },
      {
        country: 'PE',
        options: [
          { nome: '>F3', valor: "PK3" },
          { nome: 'F3', valor: "PK2" },
          { nome: 'F2', valor: "PK1" },
          { nome: 'F1', valor: "K" },
          { nome: '1G', valor: "1" },
          { nome: '2G', valor: "2" },
          { nome: '3G', valor: "3" },
          { nome: '4G', valor: "4" },
          { nome: '5G', valor: "5" },
          { nome: '6G', valor: "6" },
          { nome: '7G', valor: "7" },
          { nome: '8G', valor: "8" },
          { nome: '9G', valor: "9" },
          { nome: '10G', valor: "10" },
          { nome: '11G', valor: "11" },
          { nome: 'UNIV', valor: "12" },
          { nome: 'AD', valor: "13" },
          { nome: 'N/A', valor: "14" }
        ]
      },
      {
        country: 'UY',
        options: [
          { nome: 'Pre 4 o menos', valor: "PK3" },
          { nome: 'Pre 5', valor: "PK2" },
          { nome: 'Pre 6', valor: "PK1" },
          { nome: 'Kinder', valor: "K" },
          { nome: '1º P (1º ano de primária)', valor: "1" },
          { nome: '2º P (2º ano de primária)', valor: "2" },
          { nome: '3º P (3º ano de primária)', valor: "3" },
          { nome: '4º P (4º ano de primária)', valor: "4" },
          { nome: '5º P (5º ano de primária)', valor: "5" },
          { nome: '6º P (6º ano de primária)', valor: "6" },
          { nome: '1º S (1º ano de secundária)', valor: "7" },
          { nome: '2º S (2º ano de secundária)', valor: "8" },
          { nome: '3º S (3º ano de secundária)', valor: "9" },
          { nome: '4º S (4º ano de secundária)', valor: "10" },
          { nome: '5º S (5º ano de secundária)', valor: "11" },
          { nome: '6º S (6º ano de secundária)', valor: "12" },
          { nome: 'Adultos', valor: "13" },
          { nome: 'EE', valor: "14" }
        ]
      },
    ]

    return this.selectLanguage(content).options
  }

  get estados() {

    switch (this.country) {

      case 'BO':
        return [
          { "nome": "Beni", "sigla": "Beni" },
          { "nome": "Chuquisaca", "sigla": "Chuquisaca" },
          { "nome": "Cochabamba", "sigla": "Cochabamba" },
          { "nome": "La Paz", "sigla": "La Paz" },
          { "nome": "Oruro", "sigla": "Oruro" },
          { "nome": "Pando", "sigla": "Pando" },
          { "nome": "Potosí", "sigla": "Potosí" },
          { "nome": "Santa Cruz	", "sigla": "Santa Cruz" },
          { "nome": "Tarija", "sigla": "Tarija" },
        ]

      case 'PE':
        return [
          { "nome": "Amazonas", "sigla": "Amazonas" },
          { "nome": "Áncash", "sigla": "Áncash" },
          { "nome": "Apurímac", "sigla": "Apurímac" },
          { "nome": "Arequipa", "sigla": "Arequipa" },
          { "nome": "Ayacucho", "sigla": "Ayacucho" },
          { "nome": "Cajamarcare", "sigla": "Cajamarca" },
          { "nome": "Callao", "sigla": "Callao" },
          { "nome": "Cusco", "sigla": "Cusco" },
          { "nome": "Huancavelica", "sigla": "Huancavelica" },
          { "nome": "Huánuco", "sigla": "Huánuco" },
          { "nome": "Ica", "sigla": "Ica" },
          { "nome": "Junín", "sigla": "Junín" },
          { "nome": "La Libertad", "sigla": "La Libertad" },
          { "nome": "Lambayeque", "sigla": "Lambayeque" },
          { "nome": "Lima", "sigla": "Lima" },
          { "nome": "Loreto", "sigla": "Loreto" },
          { "nome": "Madre de Dios", "sigla": "Madre de Dios" },
          { "nome": "Moquegua", "sigla": "Moquegua" },
          { "nome": "Pasco", "sigla": "Pasco" },
          { "nome": "Piura", "sigla": "Piura" },
          { "nome": "Puno", "sigla": "Puno" },
          { "nome": "San Martín", "sigla": "San Martín" },
          { "nome": "Tacna", "sigla": "Tacna" },
          { "nome": "Tumbes", "sigla": "Tumbes" },
          { "nome": "Ucayali", "sigla": "Ucayali" },
        ]

      case 'UY':
        return [
          { "nome": "Artigas", "sigla": "Artigas" },
          { "nome": "Canelones", "sigla": "Canelones" },
          { "nome": "Cerro Largo", "sigla": "Cerro Largo" },
          { "nome": "Colonia", "sigla": "Colonia" },
          { "nome": "Durazno", "sigla": "Durazno" },
          { "nome": "Flores", "sigla": "Flores" },
          { "nome": "Florida", "sigla": "Florida" },
          { "nome": "Lavalleja", "sigla": "Lavalleja" },
          { "nome": "Maldonado", "sigla": "Maldonado" },
          { "nome": "Montevideo", "sigla": "Montevideo" },
          { "nome": "Paysandú", "sigla": "Paysandú" },
          { "nome": "Río Negro", "sigla": "Río Negro" },
          { "nome": "Rivera", "sigla": "Rivera" },
          { "nome": "Rocha", "sigla": "Rocha" },
          { "nome": "Salto", "sigla": "Salto" },
          { "nome": "San José", "sigla": "San José" },
          { "nome": "Soriano", "sigla": "Soriano" },
          { "nome": "Tacuarembó", "sigla": "Tacuarembó" },
          { "nome": "Treinta y Tres", "sigla": "Treinta y Tres" },
        ]

      default:

        return [
          { "nome": "Acre", "sigla": "AC" },
          { "nome": "Alagoas", "sigla": "AL" },
          { "nome": "Amapá", "sigla": "AP" },
          { "nome": "Amazonas", "sigla": "AM" },
          { "nome": "Bahia", "sigla": "BA" },
          { "nome": "Ceará", "sigla": "CE" },
          { "nome": "Distrito Federal", "sigla": "DF" },
          { "nome": "Espírito Santo", "sigla": "ES" },
          { "nome": "Goiás", "sigla": "GO" },
          { "nome": "Maranhão", "sigla": "MA" },
          { "nome": "Mato Grosso", "sigla": "MT" },
          { "nome": "Mato Grosso do Sul", "sigla": "MS" },
          { "nome": "Minas Gerais", "sigla": "MG" },
          { "nome": "Pará", "sigla": "PA" },
          { "nome": "Paraíba", "sigla": "PB" },
          { "nome": "Paraná", "sigla": "PR" },
          { "nome": "Pernambuco", "sigla": "PE" },
          { "nome": "Piauí", "sigla": "PI" },
          { "nome": "Rio de Janeiro", "sigla": "RJ" },
          { "nome": "Rio Grande do Norte", "sigla": "RN" },
          { "nome": "Rio Grande do Sul", "sigla": "RS" },
          { "nome": "Rondônia", "sigla": "RO" },
          { "nome": "Roraima", "sigla": "RR" },
          { "nome": "Santa Catarina", "sigla": "SC" },
          { "nome": "São Paulo", "sigla": "SP" },
          { "nome": "Sergipe", "sigla": "SE" },
          { "nome": "Tocantins", "sigla": "TO" }
        ]
    }

  }

  get prefixo() {

    let content: Prefix[] = [
      {
        country: 'BR',
        prefix: '+55',
      },
      {
        country: 'CL',
        prefix: '+56',
      },
      {
        country: 'CO',
        prefix: '+57',
      },
      {
        country: 'AR',
        prefix: '+54',
      },
      {
        country: 'BO',
        prefix: '+591',
      },
      {
        country: 'PE',
        prefix: '+51',
      },
      {
        country: 'UY',
        prefix: '+598',
      },
    ]

    return this.selectLanguage(content).prefix
  }

  get selectCountryPhone() {

    const content: SelectCountryPhone[] = [
      {
        country: 'BR',
        selectOtherCountry: 'Outro',
        placehoderKSACountry: 'Cód. localidade + número',
        placehoderOtherCountry: 'Cód. país + localidade + número'
      },
      {
        country: 'HH',
        selectOtherCountry: 'Otro',
        placehoderKSACountry: 'Cód. localidad + número',
        placehoderOtherCountry: 'Cód. país + localidad + número'
      }
    ]

    return this.selectLanguage(content)
  }

  get calendar() {

    let content: Calendar[] = [
      {
        country: 'BR',
        dayNames: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
        monthNamesShort: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        today: 'Hoje',
        clear: 'Limpar'
      },
      {
        country: 'HH',
        dayNames: [
          'Domingo',
          'Lunes',
          'Martes',
          'Miércoles',
          'Jueves',
          'Viernes',
          'Sábado',
        ],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
        monthNames: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        monthNamesShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
        today: 'Hoy',
        clear: 'Limpiar'
      }
    ]

    return this.selectLanguage(content)
  }



  apiError(error: string) {

    let listError = [
      {
        code: 'ERR001',
        BR: 'Aplicativo não cadastrado. (Cód: E0028)',
        HH: 'Aplicación no registrada. (Cód: E0028)'
      },
      {
        code: 'ERR002',
        BR: 'Usuário ou senha inválidos. (Cód: E0029)',
        HH: 'El nombre de usuario o la contraseña no son válidos. (Cód: E0029)'
      },
      {
        code: 'ERR003',
        BR: 'Não encontrado elemento correspondente. (Cód: E0030)',
        HH: 'No se encontró ningún elemento coincidente. (Cód: E0030)'
      },
      {
        code: 'ERR004',
        BR: 'E-mail inválido. (Cód: E0031)',
        HH: 'e-mail inválido. (Cód: E0031)'
      },
      {
        code: 'ERR005',
        BR: 'Matrícula já existente. (Cód: E0032)',
        HH: 'Registro existente. (Cód: E0032)'
      },
      {
        code: 'ERR006',
        BR: 'Erro ao adicionar nova matrícula. (Cód: E0033)',
        HH: 'Error al agregar nueva matrícula. (Cód: E0033)'
      },
      {
        code: 'ERR007',
        BR: 'Erro ao obter condições de matrícula. (Cód: E0034)',
        HH: 'Error al obtener las condiciones de matrícula. (Cód: E0034)'
      },
      {
        code: 'ERR008',
        BR: 'Erro ao obter lista de disciplinas da unidade. (Cód: E0035)',
        HH: 'Error al obtener la lista de cursos de la unidad. (Cód: E0035)'
      },
      {
        code: 'ERR009',
        BR: 'Erro ao atualizar data de sincronização com KSiS. (Cód: E0036)',
        HH: 'Error al actualizar la fecha de sincronización con KSiS. (Cód: E0036)'
      },
      {
        code: 'ERR010',
        BR: 'Erro ao obter lista de alunos com aprovação pendente. (Cód: E0037)',
        HH: 'Error al obtener la lista de alumnos pendientes de aprobación. (Cód: E0037)'
      },
      {
        code: 'ERR011',
        BR: 'Erro ao obter aluno com esses parâmetros. (Cód: E0038)',
        HH: 'Error al obtener estudiante con estos parámetros. (Cód: E0038)'
      },
      {
        code: 'ERR012',
        BR: 'Erro ao obter alunos para registrar no Teams. (Cód: E0039)',
        HH: 'Error al hacer que los estudiantes se inscriban en Teams. (Cód: E0039)'
      },
      {
        code: 'ERR013',
        BR: 'Erro ao obter detalhes de enrollment. (Cód: E0040)',
        HH: 'Error al obtener los detalles de enrollment. (Cód: E0040)'
      },
      {
        code: 'ERR030',
        BR: 'Lead não encontrado. (Cód: E0058)',
        HH: 'Lead no encontrado. (Cód: E0058)'
      },
      {
        code: 'ERR031',
        BR: 'Erro ao obter dados básicos para matrícula. (Cód: E0059)',
        HH: 'Error al obtener los datos de registro básicos. (Cód: E0059)'
      },
      {
        code: 'ERR032',
        BR: 'Erro ao obter alunos para sincronizar. (Cód: E0060)',
        HH: 'Error al hacer que los estudiantes se sincronicen. (Cód: E0060)'
      },
      {
        code: 'ERR033',
        BR: 'Aluno não encontrado. (Cód: E0061)',
        HH: 'Estudiante no encontrado. (Cód: E0061)'
      },
      {
        code: 'ERR041',
        BR: 'Erro ao enviar e-mail de congratulações. (Cód: E0069)',
        HH: 'Error al enviar e-mail de felicitaciones. (Cód: E0069)'
      }
    ]

    let index = listError.findIndex(item => {
      return item.code === error
    })

    if (this.country === 'BR') return listError[index].BR

    return listError[index].HH
  }
}
