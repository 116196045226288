import { AbstractControl } from '@angular/forms'
import { validate } from 'gerador-validador-cpf'
import * as moment from 'moment'

export function futureDate(control: AbstractControl) {

    if (control.value.length === 10) {
        let today = moment()
        let inputDate = moment(control.value, 'DD/MM/YYYY')

        if (inputDate.isAfter(today)) {
            return { futureDate: true }
        }
    }
    return null

}

export function pastDate(control: AbstractControl) {

    if (control.value.length === 10) {
        let cemAnos = moment().subtract(100, 'years')
        let inputDate = moment(control.value, 'DD/MM/YYYY')

        if (inputDate.isBefore(cemAnos)) {
            return { pastDate: true }
        }
    }

    return null
}

export function minDate(control: AbstractControl) {

    if (control.value.length === 10) {
        let min = moment().subtract(1, 'days')
        let max = moment()
        let inputDate = moment(control.value, 'DD/MM/YYYY')

        if (inputDate.isBetween(min, max)) {
            return { minDate: true }
        }
    }


    return null
}

export function invalidDate(control: AbstractControl) {

    let inputDate = moment(control.value, 'DD/MM/YYYY')

    if (control.value.length === 10) {
        if (!inputDate.isValid()) {
            return { invalidDate: true }
        }
    } else if (control.value.length > 0 && control.value.length < 10) {
        return { incompleteDate: true }
    }

    return null
}



export function validCPF(control: AbstractControl) {

    let cpf = control.value

    if (cpf != '' && cpf.length === 11) {
        if (!validate(cpf)) {
            return { validCPF: true }
        }
    }

    return null
}

export function validRUT(control: AbstractControl) {

    let value = control.value

    const validate = (rutCompleto: any) => {

        let str = rutCompleto.split('')

        let digv = str.slice(-1).join('')
        let rut = str.slice(0, -1).join('')

        if (digv == 'K') digv = 'k'

        return dv(rut) == digv
    }

    const dv = (T: any) => {

        let str = T

        let M = 0, S = 1

        for (; str; str = Math.floor(str / 10))
            S = (S + str % 10 * (9 - M++ % 6)) % 11
        return S ? S - 1 : 'k'
    }

    if (value != '') {
        if (!validate(value)) {
            return { validRUT: true }
        }
    }

    return null
}