<app-step></app-step>

<div class="k-container">
  <div class="principal">
    <h4 class="titulo">{{ lang.title }}</h4>

    <p class="titulo2">{{ lang.greeting }}</p>

    <!-- <p class="txt-sucesso">
      <i class="fas fa-check-circle"></i>
      <span>Matrícula concluída com sucesso!</span>
    </p> -->

    <p class="textos">
      {{ lang.textEnrollmentComplete1 }}
      <span> {{ nomeAluno }} </span>
      {{ lang.textEnrollmentComplete2 }}
    </p>

    <p class="textos">
      {{ lang.textEmailConfirmation }}
    </p>

    <div>
      <p class="textos">{{ lang.textGeneratedCode }}</p>
      <p class="boxCodigo">{{ protocolo }}</p>
    </div>

    <p class="textos">
      {{ lang.textNotification }}
    </p>

    <!-- <button type="button" class="btnNovoAluno" (click)='novaMatricula()' *ngIf="hasNextHash">
      <i class="fas fa-user-plus"></i>
      {{ langButton.newChild }}
    </button> -->

    <div *ngIf="hasNextHash">
      <p class="textos">
        {{ lang.textNew }}
        <a href="" (click)="novaMatricula($event)">{{ lang.linkClickHere }}</a>.
      </p>

      <p class="textos">{{ lang.textOtherwiseCloseWindow }}</p>
    </div>

    <p *ngIf="!hasNextHash" class="textos">{{ lang.textCloseWindow }}</p>
  </div>
</div>