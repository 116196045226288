import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Steps } from 'src/app/models/language.model'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  lang: Steps = this.language.steps
  step: string = ''

  constructor(
    private router: Router,
    private language: LanguageService
  ) { }

  ngOnInit(): void {

    this.setStep()

  }

  setStep() {
    this.step = this.router.url
  }


}
