<app-loading
  *ngIf="isLoading; else conteudo"
  [textLoading]="textLoading"
></app-loading>

<!-- <app-loading *ngIf="isLoading; else conteudo"></app-loading> -->

<ng-template #conteudo [ngSwitch]="isAuthenticated">
  <div class="k-container">
    <div class="principal">
      <div *ngSwitchCase="true">
        <div class="card">
          <p class="card__titulo">
            {{ lang.email }}
          </p>
          <span class="email"> {{ displayEmail }} </span>
        </div>

        <p class="texto">{{ lang.confirmEmail }}</p>

        <div class="ipt" [class.iptTxtErro]="!emailValido">
          <input
            class="ipt__campo"
            type="email"
            [placeholder]="lang.placeholder"
            [formControl]="emailUsuario"
            (focus)="limpaErro()"
            (keydown.enter)="proximaPagina()"
          />
          <app-input-error
            [textError]="msgError"
            *ngIf="!emailValido"
          ></app-input-error>
        </div>

        <div class="navigate-button">
          <button
            class="botaoContinuar"
            [class.btnCarregando]="botaoCarregando"
            [class.btnInativo]="emailUsuario.status == 'INVALID'"
            (click)="proximaPagina()"
          >
            <span> {{ langButton.start }} </span>
            <i [class]="iconeBotaoEmail"></i>
          </button>
        </div>
      </div>

      <app-message
        *ngSwitchCase="false"
        icon="fas fa-exclamation"
        colorIcon="error"
        [titulo]="titleMessage"
      >
        <p [innerHTML]="textMessage | sanitizeHtml"></p>
      </app-message>
    </div>
  </div>
  <app-help-button *ngSwitchCase="true"></app-help-button>
</ng-template>
