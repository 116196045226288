<app-loading *ngIf="isLoading; else conteudo"></app-loading>

<ng-template #conteudo [ngSwitch]="isConfirm">
  <app-step></app-step>

  <div class="k-container">
    <div class="principal">
      <div *ngSwitchCase="true">
        <h4 class="titulo" id="titulo">{{ lang.title }}</h4>

        <p
          class="textoCondicoes"
          [innerHTML]="textoCondicoes | sanitizeHtml"
        ></p>

        <div class="navigate-button">
          <button class="botaoVoltar" type="button" (click)="voltaPagina()">
            <i class="fas fa-arrow-alt-circle-left"></i>
            <span> {{ langButton.back }} </span>
          </button>
          <button class="botaoContinuar" (click)="proximaPagina()">
            <span> {{ langButton.acceptAndComplete }} </span>
            <i class="fas fa-arrow-alt-circle-right"></i>
          </button>
        </div>
      </div>

      <app-message
        *ngSwitchCase="false"
        [icon]="icon"
        [colorIcon]="colorIcon"
        [titulo]="tituloMensagem"
        class="containerMessageError"
      >
        <p [innerHTML]="textoMensagem | sanitizeHtml"></p>
        <button
          type="button"
          class="btnMain"
          (click)="fecharErro()"
          *ngIf="mostraBotaoTentarNovamente"
        >
          {{ langButton.tryAgain }}
        </button>
      </app-message>
    </div>
  </div>

  <app-help-button *ngSwitchCase="true"></app-help-button>
</ng-template>
