<app-loading *ngIf="isLoadingPage; else conteudo"></app-loading>

<ng-template #conteudo>
  <app-step></app-step>

  <div class="k-container">
    <div class="principal">
      <h4 class="titulo">{{ lang.title }}</h4>

      <div class="box-title box-child">
        <h4 #stickyMenu [class.sticky]="sticky">
          <div class="inner-box">
            <i class="fas fa-child"></i>
            <div>{{ lang.caption }} <span> {{ lang.formType }} </span></div>
          </div>
        </h4>
      </div>

      <div [class]="classeAtiva">
        <div class="tituloStep titulo1">
          <span class="tituloStep__numeroContainer">
            <span class="tituloStep__numero"> 1 </span></span>
          <span class="tituloStep__text"> {{ lang.stepOne }} </span>
        </div>
        <div class="spacer1"><span class="spacer__border"> </span></div>
        <div class="content1" *ngIf="steps[0].ativo">
          <form [formGroup]="formDadosAluno" class="form1">
            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelFullName }} </label>
              <input
                class="ipt__campo"
                formControlName="nome"
                type="text"
                [placeholder]="lang.placeholderFullName"
                maxlength="40"
                (paste)="$event.preventDefault()"
                (keypress)="alphaOnly($event)" />
              <div
                *ngIf="
                  formDadosAluno.get('nome')?.invalid &&
                  (formDadosAluno.get('nome')?.dirty ||
                    formDadosAluno.get('nome')?.touched)
                ">
                <app-input-error
                  *ngIf="formDadosAluno.get('nome')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formDadosAluno.get('nome')?.errors?.['pattern']"
                  [textError]="langInputError.incompleteFullName"></app-input-error>
              </div>
            </div>

            <div class="split-field">
              <div class="select div-genero">
                <label class="ipt__label"> {{ lang.labelGender }} </label>
                <select
                  class="ipt__campo"
                  formControlName="genero"
                  #genero
                  (change)="
                    formataGenero(
                      genero.options[genero.options.selectedIndex].text
                    )
                  ">
                  <option value="" [selected]="true" [hidden]="true">
                    {{ lang.placeholderGender }}
                  </option>
                  <option *ngFor="let genero of generos" [value]="genero.valor">
                    {{ genero.nome }}
                  </option>
                </select>
                <div
                  *ngIf="
                    formDadosAluno.get('genero')?.invalid &&
                    (formDadosAluno.get('genero')?.dirty ||
                      formDadosAluno.get('genero')?.touched)
                  ">
                  <app-input-error
                    *ngIf="formDadosAluno.get('genero')?.errors?.['required']"
                    [textError]="langInputError.required"></app-input-error>
                </div>
              </div>

              <div class="ipt div-nascimento">
                <label class="ipt__label"> {{ lang.labelDateOfBirth }} </label>
                <input
                  class="ipt__campo"
                  formControlName="nascimento"
                  type="text"
                  inputmode="numeric"
                  mask="00/00/0000"
                  [dropSpecialCharacters]="false"
                  [placeholder]="placeholderDate" />
                <div
                  *ngIf="
                    formDadosAluno.get('nascimento')?.invalid &&
                    (formDadosAluno.get('nascimento')?.dirty ||
                      formDadosAluno.get('nascimento')?.touched)
                  ">
                  <app-input-error
                    *ngIf="formDadosAluno.get('nascimento')?.errors?.['required']"
                    [textError]="langInputError.required"></app-input-error>
                  <app-input-error
                    *ngIf="formDadosAluno.get('nascimento')?.errors?.futureDate"
                    [textError]="langInputError.futureDate"></app-input-error>
                  <app-input-error
                    *ngIf="formDadosAluno.get('nascimento')?.errors?.pastDate"
                    [textError]="langInputError.pastDate"></app-input-error>
                  <app-input-error
                    *ngIf="
                      formDadosAluno.get('nascimento')?.errors?.invalidDate
                    "
                    [textError]="langInputError.invalidDate"></app-input-error>
                  <app-input-error
                    *ngIf="
                      formDadosAluno.get('nascimento')?.errors?.incompleteDate
                    "
                    [textError]="langInputError.incompleteDate"></app-input-error>
                  <app-input-error
                    *ngIf="formDadosAluno.get('nascimento')?.errors?.minDate"
                    [textError]="langInputError.minDate"></app-input-error>
                </div>
              </div>
            </div>

            <div class="split-field">
              <div class="select">
                <label class="ipt__label"> {{ lang.labelSchoolGrade }} </label>
                <button
                  class="btnModalAnoEscolar"
                  (click)="openModalAnoEscolar($event)">
                  <i class="fas fa-question-circle"></i>
                </button>
                <select
                  class="ipt__campo"
                  formControlName="serie"
                  #serie
                  (change)="
                    formataSerieEscolar(
                      serie.options[serie.options.selectedIndex].text
                    )
                  ">
                  <option value="" [selected]="true" [hidden]="true">
                    {{ lang.placeholderSchoolGrade }}
                  </option>
                  <option
                    *ngFor="let serie of seriesEscolares"
                    [value]="serie.valor">
                    {{ serie.nome }}
                  </option>
                </select>
                <div
                  *ngIf="
                    formDadosAluno.get('serie')?.invalid &&
                    (formDadosAluno.get('serie')?.dirty ||
                      formDadosAluno.get('serie')?.touched)
                  ">
                  <app-input-error
                    *ngIf="formDadosAluno.get('serie')?.errors?.['required']"
                    [textError]="langInputError.required"></app-input-error>
                </div>
              </div>

              <div class="ipt">
                <label class="ipt__label"> {{ lang.labelSchoolName }} </label>
                <input
                  class="ipt__campo"
                  type="text"
                  formControlName="escola"
                  maxlength="80"
                  [placeholder]="lang.placeholderSchoolName" />
              </div>
            </div>

            <div class="split-field">
              <div class="ipt">
                <label class="ipt__label"> {{ lang.labelPhone }} </label>

                <div class="container-code-number">
                  <ng-select
                    #select
                    appendTo=".ipt"
                    class="code"
                    [items]="allCountries"
                    bindLabel="name"
                    bindValue="dialCode"
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="code"
                    (change)="onSelectCountryCode($event.isoCode)">
                    <ng-template ng-label-tmp let-item="item">
                      <img
                        *ngIf="item.flag"
                        class="label-img"
                        [src]="item.flag"
                        [alt]="item.name" />
                      <span *ngIf="!item.flag" class="label-name">{{
                        item.name
                        }}</span>
                      <span class="label-code">{{ item.dialCode }}</span>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                      <img
                        *ngIf="item.flag"
                        class="option-img"
                        [src]="item.flag"
                        [alt]="item.name" />
                      <span class="option-name">{{ item.name }}</span>
                      <span class="option-code">{{ item.dialCode }}</span>
                    </ng-template>
                  </ng-select>

                  <input
                    class="ipt__campo"
                    type="text"
                    formControlName="telefone"
                    [mask]="cellphoneFormat"
                    inputmode="numeric"
                    [placeholder]="cellphonePlaceholder" />
                </div>

                <div
                  *ngIf="
                    formDadosAluno.get('telefone')?.invalid &&
                    (formDadosAluno.get('telefone')?.dirty ||
                      formDadosAluno.get('telefone')?.touched)
                  ">
                  <app-input-error
                    *ngIf="formDadosAluno.get('telefone')?.errors?.['required']"
                    [textError]="langInputError.required"></app-input-error>
                  <app-input-error
                    *ngIf="formDadosAluno.get('telefone')?.errors?.minlength"
                    [textError]="langInputError.incompletePhone"></app-input-error>
                </div>
              </div>

              <div class="ipt iptEmail">
                <label class="ipt__label"> {{ lang.labelEmail }} </label>
                <input
                  class="ipt__campo"
                  type="email"
                  formControlName="email"
                  maxlength="100"
                  [placeholder]="lang.placeholderEmail" />
                <div
                  *ngIf="
                    formDadosAluno.get('email')?.invalid &&
                    (formDadosAluno.get('email')?.dirty ||
                      formDadosAluno.get('email')?.touched)
                  ">
                  <app-input-error
                    *ngIf="formDadosAluno.get('email')?.errors?.['email']"
                    [textError]="langInputError.invalidEmail"></app-input-error>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="tituloStep titulo2">
          <span class="tituloStep__numeroContainer">
            <span class="tituloStep__numero"> 2 </span>
          </span>
          <span class="tituloStep__text"> {{ lang.stepTwo }} </span>
        </div>

        <div class="spacer2"><span class="spacer__border"> </span></div>

        <div class="content2" *ngIf="steps[1].ativo">
          <form class="containerCEP" [formGroup]="formEnderecoAluno">
            <div
              class="ipt"
              *ngIf="displayForm.cep"
              [style.order]="addressOrder.cep">
              <label class="ipt__label">
                {{ langEndereco.labelPostalCode }}
              </label>
              <input
                class="ipt__campo"
                formControlName="cep"
                type="text"
                [placeholder]="langEndereco.placeholderPostalCode"
                mask="00000-000"
                (keyup)="consultaCEP($event)"
                (keypress)="consultaCEP($event)"
                inputmode="numeric"
                [tabindex]="addressOrder.cep" />
              <app-loading-inner *ngIf="isLoading"></app-loading-inner>
              <app-input-error
                [textError]="langInputError.postalCodeNotFound"
                *ngIf="isCEPinvalid"></app-input-error>
              <div
                *ngIf="
                  formEnderecoAluno.get('cep')?.invalid &&
                  (formEnderecoAluno.get('cep')?.dirty ||
                    formEnderecoAluno.get('cep')?.touched)
                ">
                <app-input-error
                  *ngIf="formEnderecoAluno.get('cep')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formEnderecoAluno.get('cep')?.errors?.minlength"
                  [textError]="langInputError.incompletePostalCode"></app-input-error>
              </div>
            </div>

            <div
              class="ipt"
              *ngIf="displayForm.endereco"
              [style.order]="addressOrder.endereco">
              <label class="ipt__label">
                {{ langEndereco.labelAddress1 }}
              </label>
              <input
                class="ipt__campo"
                formControlName="endereco"
                type="text"
                maxlength="50"
                [placeholder]="langEndereco.placeholderAddress1"
                [tabindex]="addressOrder.endereco" />
              <div
                *ngIf="
                  formEnderecoAluno.get('endereco')?.invalid &&
                  (formEnderecoAluno.get('endereco')?.dirty ||
                    formEnderecoAluno.get('endereco')?.touched)
                ">
                <app-input-error
                  *ngIf="formEnderecoAluno.get('endereco')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formEnderecoAluno.get('endereco')?.errors?.['pattern']"
                  [textError]="langInputError.required"></app-input-error>
              </div>
            </div>

            <div
              class="split-field"
              [style.order]="addressOrder.splitField.order">
              <div
                class="ipt"
                *ngIf="displayForm.numero"
                [style.order]="addressOrder.splitField.numero">
                <label class="ipt__label">
                  {{ langEndereco.labelAddress2 }}
                </label>
                <input
                  class="ipt__campo"
                  formControlName="numero"
                  type="text"
                  [placeholder]="langEndereco.placeholderAddress2"
                  maxlength="40"
                  [tabindex]="addressOrder.splitField.numero" />
                <div
                  *ngIf="
                    formEnderecoAluno.get('numero')?.invalid &&
                    (formEnderecoAluno.get('numero')?.dirty ||
                      formEnderecoAluno.get('numero')?.touched)
                  ">
                  <app-input-error
                    *ngIf="formEnderecoAluno.get('numero')?.errors?.['required']"
                    [textError]="langInputError.required"></app-input-error>
                  <app-input-error
                    *ngIf="formEnderecoAluno.get('numero')?.errors?.['pattern']"
                    [textError]="langInputError.required"></app-input-error>
                </div>
              </div>

              <div
                class="ipt"
                *ngIf="displayForm.complemento"
                [style.order]="addressOrder.splitField.complemento">
                <label class="ipt__label">
                  {{ langEndereco.labelAddress3 }}
                </label>
                <input
                  class="ipt__campo"
                  formControlName="complemento"
                  type="text"
                  [placeholder]="langEndereco.placeholderAddress3"
                  maxlength="40"
                  [tabindex]="addressOrder.splitField.complemento" />
              </div>
            </div>

            <div
              class="ipt"
              *ngIf="displayForm.bairro"
              [style.order]="addressOrder.bairro">
              <label class="ipt__label">
                {{ langEndereco.labelAddress4 }}
              </label>
              <input
                class="ipt__campo"
                formControlName="bairro"
                type="text"
                [placeholder]="langEndereco.placeholderAddress4"
                maxlength="40"
                [tabindex]="addressOrder.bairro" />
              <div
                *ngIf="
                  formEnderecoAluno.get('bairro')?.invalid &&
                  (formEnderecoAluno.get('bairro')?.dirty ||
                    formEnderecoAluno.get('bairro')?.touched)
                ">
                <app-input-error
                  *ngIf="formEnderecoAluno.get('bairro')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formEnderecoAluno.get('bairro')?.errors?.['pattern']"
                  [textError]="langInputError.required"></app-input-error>
              </div>
            </div>

            <div
              class="ipt"
              *ngIf="displayForm.estado"
              [style.order]="addressOrder.estado">
              <label class="ipt__label"> {{ langEndereco.labelState }} </label>
              <select
                class="ipt__campo"
                formControlName="estado"
                #estado
                [tabindex]="addressOrder.estado"
                (change)="
                  formataEstado(
                    estado.options[estado.options.selectedIndex].text
                  )
                ">
                <option value="" [selected]="true" [hidden]="true">
                  {{ langEndereco.placeholderState }}
                </option>
                <option *ngFor="let estado of estados" [value]="estado.sigla">
                  {{ estado.nome }}
                </option>
              </select>
              <div
                *ngIf="
                  formEnderecoAluno.get('estado')?.invalid &&
                  (formEnderecoAluno.get('estado')?.dirty ||
                    formEnderecoAluno.get('estado')?.touched)
                ">
                <app-input-error
                  *ngIf="formEnderecoAluno.get('estado')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
              </div>
            </div>

            <div
              class="ipt"
              *ngIf="displayForm.cidade"
              [style.order]="addressOrder.cidade">
              <label class="ipt__label"> {{ langEndereco.labelCity }} </label>
              <input
                class="ipt__campo"
                formControlName="cidade"
                type="text"
                maxlength="35"
                [placeholder]="langEndereco.placeholderCity"
                [tabindex]="addressOrder.cidade" />
              <div
                *ngIf="
                  formEnderecoAluno.get('cidade')?.invalid &&
                  (formEnderecoAluno.get('cidade')?.dirty ||
                    formEnderecoAluno.get('cidade')?.touched)
                ">
                <app-input-error
                  *ngIf="formEnderecoAluno.get('cidade')?.errors?.['required']"
                  [textError]="langInputError.required"></app-input-error>
                <app-input-error
                  *ngIf="formEnderecoAluno.get('cidade')?.errors?.['pattern']"
                  [textError]="langInputError.required"></app-input-error>
              </div>
            </div>
          </form>
        </div>

        <div class="tituloStep titulo3">
          <span class="tituloStep__numeroContainer">
            <span class="tituloStep__numero"> 3 </span>
          </span>
          <span class="tituloStep__text"> {{ lang.stepThree }} </span>
        </div>
        <div class="spacer3"><span class="spacer__border"> </span></div>
        <div class="content3" *ngIf="steps[2].ativo">
          <p class="tituloValidacao">
            {{ lang.captionStepThree }}
          </p>
          <div class="ipt">
            <label class="ipt__label"> {{ lang.labelFullName }} </label>
            <input
              class="ipt__campo"
              readonly
              type="text"
              value="{{ formDadosAluno.get('nome')?.value }}" />
          </div>

          <div class="split-field">
            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelGender }} </label>
              <input
                class="ipt__campo"
                readonly
                type="text"
                value="{{ generoNome }}" />
            </div>

            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelDateOfBirth }} </label>
              <input
                class="ipt__campo"
                readonly
                type="text"
                value="{{ formDadosAluno.get('nascimento')?.value }}" />
            </div>
          </div>

          <div class="split-field">
            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelSchoolGrade }} </label>
              <input
                class="ipt__campo"
                readonly
                type="text"
                value="{{ serieEscolarNome }}" />
            </div>

            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelSchoolName }} </label>
              <input
                class="ipt__campo"
                readonly
                type="text"
                value="{{ formDadosAluno.get('escola')?.value }}" />
            </div>
          </div>
          <div class="split-field">
            <div class="ipt">
              <label class="ipt__label"> {{ lang.labelPhone }} </label>
              <input
                class="ipt__campo"
                type="text"
                readonly
                value="{{
                  formDadosAluno.get('telefone')?.value | mask : cellphoneFormat
                }}" />
            </div>

            <div class="ipt iptEmail">
              <label class="ipt__label"> {{ lang.labelEmail }} </label>
              <input
                class="ipt__campo"
                type="text"
                readonly
                value="{{ formDadosAluno.get('email')?.value }}" />
            </div>
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.cep"
            [style.order]="addressOrder.cep">
            <label class="ipt__label">
              {{ langEndereco.labelPostalCode }}
            </label>
            <input
              class="ipt__campo"
              type="text"
              readonly
              value="{{
                formEnderecoAluno.get('cep')?.value | mask : '00000-000'
              }}" />
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.endereco"
            [style.order]="addressOrder.endereco">
            <label class="ipt__label"> {{ langEndereco.labelAddress1 }} </label>
            <input
              class="ipt__campo"
              type="text"
              readonly
              value="{{ formEnderecoAluno.get('endereco')?.value }}" />
          </div>

          <div
            class="split-field"
            [style.order]="addressOrder.splitField.order">
            <div
              class="ipt"
              *ngIf="displayForm.numero"
              [style.order]="addressOrder.splitField.numero">
              <label class="ipt__label">
                {{ langEndereco.labelAddress2 }}
              </label>
              <input
                class="ipt__campo"
                type="text"
                readonly
                value="{{ formEnderecoAluno.get('numero')?.value }}" />
            </div>

            <div
              class="ipt"
              *ngIf="displayForm.complemento"
              [style.order]="addressOrder.splitField.complemento">
              <label class="ipt__label">
                {{ langEndereco.labelAddress3 }}
              </label>
              <input
                class="ipt__campo"
                type="text"
                readonly
                value="{{ formEnderecoAluno.get('complemento')?.value }}" />
            </div>
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.bairro"
            [style.order]="addressOrder.bairro">
            <label class="ipt__label"> {{ langEndereco.labelAddress4 }} </label>
            <input
              class="ipt__campo"
              type="text"
              readonly
              value="{{ formEnderecoAluno.get('bairro')?.value }}" />
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.estado"
            [style.order]="addressOrder.estado">
            <label class="ipt__label"> {{ langEndereco.labelState }} </label>
            <input
              class="ipt__campo"
              type="text"
              readonly
              [(ngModel)]="estadoNome" />
          </div>

          <div
            class="ipt"
            *ngIf="displayForm.cidade"
            [style.order]="addressOrder.cidade">
            <label class="ipt__label"> {{ langEndereco.labelCity }} </label>
            <input
              class="ipt__campo"
              type="text"
              readonly
              value="{{ formEnderecoAluno.get('cidade')?.value }}" />
          </div>
        </div>
      </div>

      <div class="navigate-button">
        <button
          class="botaoVoltar"
          type="button"
          (click)="voltaPagina()"
          *ngIf="stepAtivo !== 0">
          <i class="fas fa-arrow-alt-circle-left"></i>
          <span> {{ langButton.back }} </span>
        </button>
        <button class="botaoContinuar" (click)="proximaPagina()">
          <span> {{ langButton.continue }} </span>
          <i class="fas fa-arrow-alt-circle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <app-help-button></app-help-button>
</ng-template>

<app-modal
  [id]="idModal"
  [titulo]="lang.modalTitle"
  [corpo]="lang.modalText"
  [btnFechar]="langButton.close"
  positionY="modal-dialog-centered"></app-modal>