<!-- Modal -->
<div class="modal fade" id="{{id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog {{size}} {{positionY}}">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ titulo }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ corpo }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-fechar" data-bs-dismiss="modal">{{ btnFechar }}</button>
          <button type="button" class="btn btn-primary" *ngIf="hasBtnSalvar">{{btnSalvar}}</button>
        </div>
      </div>
    </div>
  </div>