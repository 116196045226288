import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { PrimeNGConfig } from 'primeng/api'
import { SubjectName } from 'src/app/models/enrollment.model'
import { Buttons, Calendar, Disciplinas, ErrorMessage } from 'src/app/models/language.model'
import { ApisService } from 'src/app/services/apis.service'
import { DadosService } from 'src/app/services/dados.service'
import { LanguageService } from 'src/app/services/language.service'
import { ConfirmService } from './../../services/confirm.service'

@Component({
  selector: 'app-disciplinas',
  templateUrl: './disciplinas.component.html',
  styleUrls: ['./disciplinas.component.scss']
})
export class DisciplinasComponent implements OnInit {

  lang: Disciplinas = this.language.disciplinas
  langButton: Buttons = this.language.buttons
  langMessage: ErrorMessage = this.language.errorMessage
  langCalendar: Calendar = this.language.calendar

  country = this.dados.getDados().countryId
  botaoAtivo = true
  isLoading: boolean = true
  isDisciplinasLoaded = true
  txtDadosErrados: string = ''
  placeholderDate = 'dd/mm/aaaa'
  value!: Date
  minDate!: Date
  maxDate!: Date

  setCheckedUnidade = false
  centerId = ''
  classSubjects: any

  disciplinaSelecionadas: any[] = []
  disciplinas: SubjectName[] = []

  mostrarCalendario = false
  valorCalendario!: Number
  calendarios = [
    { label: 'A', id: 2 },
    { label: 'B', id: 8 },
  ]


  constructor(
    private router: Router,
    private apiService: ApisService,
    private confirm: ConfirmService,
    private dados: DadosService,
    private language: LanguageService,
    private config: PrimeNGConfig,
  ) { }

  ngOnInit(): void {

    this.recuperaDadosOutraUnidade()
    this.getClassSubjects()
    this.changeFormByCountry()
    console.log(this.country)

    this.configCalendar()

    this.minDate = moment().date(21).subtract(1, 'months').toDate()
    this.maxDate = moment().date(20).add(1, 'months').toDate()

  }


  onSelectDate(date: any) {
    this.confirm.setConfirm({ enrollHopeDate: moment(date).toISOString(true) })
  }

  getClassSubjects() {

    this.apiService.classSubjects(this.dados.getDados().centerId, this.dados.getDados().countryId).subscribe(
      res => {
        this.classSubjects = res
        this.isLoading = false
      },
      err => {
        this.txtDadosErrados = this.language.apiError(err.error)
        this.isDisciplinasLoaded = false
        this.isLoading = false
      }
    )
  }

  paginaAnterior() {
    this.router.navigate(['/cadastro-responsavel-financeiro'])
  }

  proximaPagina(form: any) {
    this.disciplinas = this.disciplinaSelecionadas.map(item => {
      return {
        "classSubjectId": item
      }
    })

    this.confirm.setConfirm(
      {
        classSubjects: this.disciplinas,
        date: moment().toISOString(true),
        childData: {
          ...this.confirm.getConfirm().childData,
          gradeAdvanceMonth: this.valorCalendario,
        }
      }
    )

    this.router.navigate(['/demais-condicoes'])

  }

  checkDisciplina(ev: Event) {
    let disciplinaCheck = ev.target as HTMLInputElement
    let value = disciplinaCheck.value

    if (disciplinaCheck.checked) {

      if (this.disciplinaSelecionadas.includes(value)) return
      this.disciplinaSelecionadas.push(value)

    } else {
      let idx = this.disciplinaSelecionadas.indexOf(value)

      if (idx !== -1) {
        this.disciplinaSelecionadas.splice(idx, 1)
      }
    }

    if (this.disciplinaSelecionadas.length > 0) {
      this.botaoAtivo = false
    } else {
      this.botaoAtivo = true
    }

  }

  checkOutraUnidade(ev: Event) {
    let outraUnidadeCheck = ev.target as HTMLInputElement

    if (this.confirm.hasConfirm()) {

      this.confirm.setConfirm({
        childData: {
          ...this.confirm.getConfirm().childData,
          studentEnrolledFlag: outraUnidadeCheck.checked
        }
      })
    }

  }

  recuperaDadosOutraUnidade() {

    if (!this.confirm.hasConfirm()) return

    if (this.confirm.getConfirm().childData.studentEnrolledFlag === undefined) {
      this.confirm.setConfirm({
        childData: {
          ...this.confirm.getConfirm().childData,
          studentEnrolledFlag: false
        }
      })
    } else {
      this.setCheckedUnidade = this.confirm.getConfirm().childData.studentEnrolledFlag
    }
  }

  fecharErro() {
    this.isLoading = true
    this.isDisciplinasLoaded = true
    this.getClassSubjects()
  }

  changeFormByCountry() {

    if (this.country === 'CO') {
      this.mostrarCalendario = true
    }
  }

  configCalendar() {
    this.config.setTranslation({
      firstDayOfWeek: 0,
      dayNames: this.langCalendar.dayNames,
      dayNamesShort: this.langCalendar.dayNamesShort,
      dayNamesMin: this.langCalendar.dayNamesMin,
      monthNames: this.langCalendar.monthNames,
      monthNamesShort: this.langCalendar.monthNamesShort,
      today: this.langCalendar.today,
      clear: this.langCalendar.clear,
    })
  }

}
